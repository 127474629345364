import { isEmpty } from 'lodash';

export const communicationReportTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Communication Type',
    dataIndex: 'communication_type',
    key: 'communication_type',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Recipient',
    dataIndex: 'reciever',
    key: 'reciever',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created',
    dataIndex: 'send_date',
    key: 'send_date',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
];

export const sourcingReportTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Candidate Name',
    dataIndex: 'first_name',
    key: 'first_name',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Source',
    dataIndex: 'title',
    key: 'title',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Created',
    dataIndex: 'sourced_date',
    key: 'sourced_date',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
];

export const screeningReportTableConfig = [
  {
    fixed: '',
    width: 200,
    title: 'Candidate Name',
    dataIndex: 'first_name',
    key: 'first_name',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phone_number',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Screening',
    dataIndex: 'status_display',
    key: 'status_display',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Total Experience',
    dataIndex: 'total_experience',
    key: 'total_experience',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Current Company',
    dataIndex: 'current_company',
    key: 'current_company',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Current CTC',
    dataIndex: 'current_ctc',
    key: 'current_ctc',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Expected CTC',
    dataIndex: 'expected_ctc',
    key: 'expected_ctc',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Notice Period',
    dataIndex: 'notice_period',
    key: 'notice_period',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
  {
    fixed: '',
    width: 200,
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    is_sort: true,
    is_search: true,
    fieldShow: true,
    is_filter: false,
    isExpanded: false,
    position: 0,
  },
];
export const EMAIL_COLORS = [
  {
    base: '#9471FF',
    legend: 'bg-[#9471FF]',
    text: 'text-[#9471FF]',
  },
  {
    base: '#819AFF',
    legend: 'bg-[#819AFF]',
    text: 'text-[#819AFF]',
  },
  {
    base: '#FDBA60',
    legend: 'bg-[#FDBA60]',
    text: 'text-[#FDBA60]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const WHATSAPP_COLORS = [
  {
    base: '#0492BE',
    legend: 'bg-[#0492BE]',
    text: 'text-[#0492BE]',
  },
  {
    base: '#07D4FD',
    legend: 'bg-[#07D4FD]',
    text: 'text-[#07D4FD]',
  },
  {
    base: '#58E0FA',
    legend: 'bg-[#58E0FA]',
    text: 'text-[#58E0FA]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const SMS_COLORS = [
  {
    base: '#43488A',
    legend: 'bg-[#43488A]',
    text: 'text-[#43488A]',
  },
  {
    base: '#5BA688',
    legend: 'bg-[#5BA688]',
    text: 'text-[#5BA688]',
  },
  {
    base: '#EABD24',
    legend: 'bg-[#EABD24]',
    text: 'text-[#EABD24]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
  {
    base: '#EABD24',
    legend: 'bg-[#EABD24]',
    text: 'text-[#EABD24]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const VOICE_BOT_COLORS = [
  {
    base: '#827799',
    legend: 'bg-[#827799]',
    text: 'text-[#827799]',
  },
  {
    base: '#47A5DB',
    legend: 'bg-[#47A5DB]',
    text: 'text-[#47A5DB]',
  },
  {
    base: '#49637A',
    legend: 'bg-[#49637A]',
    text: 'text-[#49637A]',
  },
  {
    base: '#FD8B92',
    legend: 'bg-[#FD8B92]',
    text: 'text-[#FD8B92]',
  },
];
export const INTERVAL_TYPES = {
  MONTHLY: 'month',
  WEEKLY: 'week',
  CUSTOM: 'day', // Custom Ranges
};
export const Dot = ({ color }) => (
  <div className={`w-2 h-7 rounded-full ${color}`}></div>
);

export const Date = ({ start, end, intervalType, dateRange }) => {
  let date = start?.format('MMMM YYYY');
  if (intervalType === INTERVAL_TYPES.WEEKLY) {
    date = `${start?.format('DD MMMM YYYY')} - ${end?.format('DD MMMM YYYY')}`;
  } else if (
    intervalType === INTERVAL_TYPES.CUSTOM &&
    !isEmpty(dateRange)
  ) {
    const [rangeStart, rangeEnd] = dateRange;
    date = `${rangeStart?.format('DD MMMM YYYY')} - ${rangeEnd?.format('DD MMMM YYYY')}`;
  }
  return (
    <h3 className="text-[#4A4A4A] font-semibold text-base">{date}</h3>
  );
};
export const CustomTooltip = ({ active, payload }) => {
  if (active && !isEmpty(payload)) {
    const markupColor = `text-[${payload[0].payload.fill}]`;
    return (
      <div className=" backdrop-blur-3xl rounded-lg py-1 px-2 bg-white">
        <p
          className={`text-xs font-normal ${markupColor}`}
        >{`${payload[0].value} ${payload[0].name}`}</p>
      </div>
    );
  }
};
