import { useState } from 'react';
import OTPInput from 'otp-input-react';
import LoginDialogHeader from '../../Components/CommonComponents/LoginDialogHeader';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import authService from '../../Services/authService';
import { toast } from 'react-hot-toast';
import Progress from '../../Components/CommonComponents/Progress';
import { useLayoutStore } from 'Store/LayoutStore';
import { filterMenusWithPermissions } from 'Utilities/componentSpecificUtilities';
import { menus, adminMenus } from 'ServiceHooks/authHooks';
import { FULL_MENU_LIST } from 'Utilities/constants';
import { Preference } from 'Controller/Preference';
const VerifyOTP = () => {
  const [OTP, setOTP] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect_url = searchParams.get('redirect_url');
  const [isprogress, setProgress] = useState(false);
  const { toggleNotification } = useLayoutStore((state) => ({
    toggleNotification: state.toggleNotification,
  }));
  const verifyOtpData = () => {
    if (OTP.length === 6) {
      sendOTP(OTP);
    } else {
      toast.error('Please Enter OTP');
      // toast((t) => (
      //   <span>

      //     <button onClick={() => toast.dismiss(t.id)}>
      //       Dismiss
      //     </button>
      //   </span>
      // ));
    }
  };

  const sendOTP = async (OTP) => {
    setProgress(true);
    var data = {
      otp: OTP,
    };
    await authService
      .verifyOtp(data)
      .then(
        (oResponse) => {
          const authData = oResponse.data.data;
          localStorage.setItem('user', JSON.stringify(authData));
          toggleNotification(true);
          // if (authData.custom_table_settings.length !== 0) {
          //   localStorage.setItem(
          //     "userPreference",
          //     JSON.stringify(authData.custom_table_settings)
          //   );
          // } else {
          //   let preferenceData = Preference();
          //   localStorage.setItem(
          //     "userPreference",
          //     JSON.stringify(preferenceData)
          //   );
          // }
          const menuType = authData.is_superadmin ? adminMenus : menus;
          const permissions = authData.permissions;
          const permittedMenu = filterMenusWithPermissions(
            permissions,
            menuType,
            FULL_MENU_LIST,
          );
          toast.success('OTP Verified');
          if (redirect_url) {
            const decoded = jwtDecode(redirect_url);
            if (decoded?.status === 'REVIEW_PENDING') {
              navigate(`/hr/interviewer/?redirect_url=${redirect_url}`);
              // navigate(0);
            }
          } else {
            navigate(permittedMenu[0].menuLink);
          navigate(0);
          }
          
          //getPreferenceData()
          setProgress(false);
        },
        (error) => {
          const errorMessage = error.response.data.error_info.otp;
          toast.error(errorMessage);
          setProgress(false);
        },
      )
      .catch((err) => {
        console.log(err);
        setProgress(false);
      });
  };

  return (
    <LoginDialogHeader>
      <div className="login">
        <OTPInput
          value={OTP}
          onChange={setOTP}
          autoFocus
          OTPLength={6}
          otpType="number"
        />
        <button onClick={() => verifyOtpData()}>Verify OTP</button>
      </div>
      {isprogress && <Progress />}
    </LoginDialogHeader>
  );
};
export default VerifyOTP;
