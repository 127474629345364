import { useQuery, useMutation } from '@tanstack/react-query';
import communicationService, {
  getWhatsAppTemplatePreview,
} from 'Services/communicationService';

export const GET_OFFER_LETTER_TRANSACTION_TYPES =
  'GET_OFFER_LETTER_TRANSACTION_TYPES';
const useGetOfferTransactionTypes = (config) => {
  const query = useQuery({
    queryKey: [GET_OFFER_LETTER_TRANSACTION_TYPES],
    queryFn: async () => {
      const response =
        await communicationService.getOfferTransactionTypes();
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

export const GET_COMMUNICATION_TRANSACTIONS =
  'GET_COMMUNICATION_TRANSACTIONS';
const useGetCommunicationTransactions = ({ params = {}, ...rest }) => {
  const query = useQuery({
    queryKey: [GET_COMMUNICATION_TRANSACTIONS],
    queryFn: async () => {
      const response =
        await communicationService.getTranscationData(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...rest,
  });

  return query;
};

export const GET_TRANSACTIONS_TEMPLATE = 'GET_TRANSACTIONS_TEMPLATE';
const useGetCommunicationTransactionsTemplate = ({ params, ...rest }) => {
  const query = useQuery({
    queryKey: [
      GET_TRANSACTIONS_TEMPLATE,
      params?.transaction_key,
      params?.is_image,
    ],
    queryFn: async () => {
      const response =
        await communicationService.getTranscationTemplateData(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...rest,
  });

  return query;
};

export const GET_RECRUITER_LIST = 'GET_RECRUITER_LIST';
const useGetRecruiters = (config) => {
  const { params, ...otherConfigs } = config;
  const query = useQuery({
    queryKey: [
      GET_RECRUITER_LIST,
      params?.job_id,
      params?.search,
      params?.job,
      params?.recruiter,
      params?.candidate,
      params?.sub_user,
    ],
    queryFn: async () => {
      const response = await communicationService.getRecruiters(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...otherConfigs,
  });
  return query;
};

export const GET_INTERVIEWERS = 'GET_INTERVIEWERS';
const useGetInterviewers = (config) => {
  const { params, ...otherConfigs } = config;
  const query = useQuery({
    queryKey: [
      GET_INTERVIEWERS,
      params?.job_id,
      params?.search,
      params?.job,
      params?.recruiter,
      params?.candidate,
      params?.sub_user,
    ],
    queryFn: async () => {
      const response = await communicationService.getInterviewers(params);
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...otherConfigs,
  });
  return query;
};

export const GET_WHATSAPP_PREVIEW = 'GET_WHATSAPP_PREVIEW';
export const useGetWhatsAppPreview = ({ params, ...rest }) => {
  const query = useQuery({
    queryKey: [GET_WHATSAPP_PREVIEW, params?.template_name],
    queryFn: async () => {
      const response = await getWhatsAppTemplatePreview(params);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

export const GET_COMMUNICATION_LOGS_LIST = 'GET_COMMUNICATION_LOGS_LIST';
const useGetCommunicationLogsList = (config) => {
  const { params, ...rest } = config;
  const query = useQuery({
    queryKey: [
      GET_COMMUNICATION_LOGS_LIST,
      params?.limit,
      params?.offset,
      params?.search,
      params?.end_date,
      params?.start_date,
      params?.job,
      params?.recruiter,
      params?.candidate,
      params?.sub_user,
    ],
    queryFn: async () => {
      const response =
        await communicationService.getCommunicationLogsWithListView(
          params,
        );
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

export const GET_COMMUNICATION_LOGS_CARDS = 'GET_COMMUNICATION_LOGS_CARDS';
const useGetCommunicationLogsCards = (config) => {
  const { params, ...otherConfigs } = config;
  const query = useQuery({
    queryKey: [
      GET_COMMUNICATION_LOGS_CARDS,
      params?.start_date,
      params?.end_date,
      params?.job,
      params?.recruiter,
      params?.candidate,
      params?.sub_user,
    ],
    queryFn: async () => {
      const response =
        await communicationService.getCommunicationLogsWithCardView(
          params,
        );
      return response?.data?.data;
    },
    retry: false,
    refetchOnWindowFocus: false,
    ...otherConfigs,
  });
  return query;
};

/* 
  Comm. > WhatsApp Tab: updating a template.
*/
export const useUpdateWhatsAppTemplate = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, params, id }) => {
      const details = await communicationService.addWhatsAppTemplate(
        body,
        params,
        id,
      );
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

/* 
  Comm. > WhatsApp Tab: adds a new template name.
*/
export const useCreateWhatsAppTemplate = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, params }) => {
      const details = await communicationService.createWhatsAppTemplate(
        body,
        params,
      );
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

export {
  useGetRecruiters,
  useGetInterviewers,
  useGetOfferTransactionTypes,
  useGetCommunicationLogsList,
  useGetCommunicationLogsCards,
  useGetCommunicationTransactions,
  useGetCommunicationTransactionsTemplate,
};
