import { Space, Button, Input, Menu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {
  levelsColumns,
  prescreenColumns,
  tableOfferColumn,
  tableRejectColumn,
} from 'pages/Hr/Jobs/components';
import {
  certificationColumn,
  departmentsColumns,
  educationDetailsColumn,
  evaluationParameterColumns,
  hiringTypeColumns,
  evaluationQuestionnaireColumn,
  locationColumn,
  profileRoleColumn,
  skillColumns,
  teamMemberColumns,
  consultanyColumns,
  prescreenParameterColumn,
  consultancyClientColumn,
  consultancyUserColumn,
  documentTypeColumns,
} from 'Components/Settings/Components';

import { CANDIDATE_TAB_CONSTANTS } from 'Utilities/constants';
import {
  jobDetailsScheduleColumnConfig,
  scheduleReportsInterviewTableConfig,
  scheduleRequestsPrescreeningTableConfig,
} from 'Components/Jobs/components';
import {
  candidatesSubTableConfig,
  mainCandidatesTableConfig,
  noShowCandidatesColumns,
  pipelineComponentColumnData,
} from 'Components/Candidate/components';
import { questionnaireColumns } from 'Components/QuestionBanks/components';
import {
  sourcingReportTableConfig,
  screeningReportTableConfig,
  communicationReportTableConfig,
} from 'Components/Communication/components';
import { enquiriesTableConfig } from 'Components/Enquiry/components';
import { licenseTableConfig } from 'Components/LicenseManagement/components';
import { useEffect } from 'react';

export const getColumnSearchProps = (
  dataIndex,
  searchInput,
  handleSearch,
  searchedColumn,
  searchText,
  handleReset,
  title,
  isResetSearch,
  setResetSearch
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
    visible
  }) => {
    // useEffect(()=> {
    //   if(isResetSearch){
    //     setResetSearch(false)
    //     if(clearFilters){
    //       handleReset(clearFilters)
    //     }
    //   }
    // },[isResetSearch])

    return (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        {dataIndex === 'experience' ? (
          <div className="flex items-center gap-2 w-[380px]">
            <Input
              ref={searchInput}
              placeholder={`Search Min Experience`}
              value={selectedKeys[0]}
              onChange={(e) => {
                const newKeys = [...selectedKeys];
                if (e.target.value === '' || !isNaN(e.target.value)) {
                  newKeys[0] = e.target.value;
                }
                setSelectedKeys(newKeys);
              }}
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{
                marginBottom: 8,
                display: 'block',
                width: '50% !important',
              }}
            />
            <Input
              ref={searchInput}
              placeholder={`Search Max Experience`}
              value={selectedKeys[1]}
              onChange={(e) => {
                const newKeys = [...selectedKeys];
                if (e.target.value === '' || !isNaN(e.target.value)) {
                  newKeys[1] = e.target.value;
                }
                setSelectedKeys(newKeys);
              }}
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{
                marginBottom: 8,
                display: 'block',
                width: '50% !important',
              }}
            />
          </div>
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${title}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys, confirm, dataIndex)
            }
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
        )}
  
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    )
  },
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />
  ),
  // onFilter: (value, record) =>
  //   record[dataIndex]
  //     .toString()
  //     .toLowerCase()
  //     .includes(value.toLowerCase()),
  // onFilterDropdownOpenChange: (visible) => {
  //   if (visible) {
  //     setTimeout(() => searchInput.current?.select(), 150);
  //   }
  // },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});

export const jobColumns = [
  {
    width: 200,
    title: 'Job Name',
    dataIndex: 'title',
    key: 'title',
    fieldShow: true,
    isExpanded: false,
    fixed: 'left',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
  },
  {
    width: 90,
    title: 'Openings',
    dataIndex: 'number_of_openings',
    key: 'number_of_openings',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
    align: 'center',
    className: 'min-width-column',
    minWidth: 100,
  },
  {
    width: 90,
    title: 'Applicants',
    dataIndex: 'total_applicants',
    key: 'total_applicants',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
    align: 'center',
    className: 'min-width-column',
  },
  {
    width: 200,
    title: 'Experience',
    dataIndex: 'experience',
    key: 'experience',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
  },
  {
    width: 200,
    title: 'Due Date',
    dataIndex: 'closure_period',
    key: 'closure_period',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 5,
  },
  {
    width: 200,
    title: 'Priority',
    dataIndex: 'priority',
    key: 'priority',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 6,
  },
  {
    width: 80,
    title: 'Offered',
    dataIndex: 'total_offered',
    key: 'total_offered',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 7,
    align: 'center',
    className: 'min-width-column',
  },
  {
    width: 200,
    title: 'Created',
    dataIndex: 'created',
    key: 'created',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 8,
  },
  {
    width: 200,
    title: 'Approver',
    dataIndex: 'reviewers',
    key: 'reviewers',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 9,
  },
  {
    width: 80,
    title: 'Hired',
    dataIndex: 'total_hired',
    key: 'total_hired',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 10,
    align: 'center',
    className: 'min-width-column',
  },
  {
    width: 80,
    title: 'Rejected',
    dataIndex: 'total_rejected',
    key: 'total_rejected',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 11,
    align: 'center',
    className: 'min-width-column',
  },
  {
    width: 200,
    title: 'Job Id',
    dataIndex: 'id',
    key: 'id',
    fieldShow: false,
    isExpanded: false,
    fixed: '',
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 12,
  },
  {
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fieldShow: false,
    isExpanded: false,
    is_search: false,
    is_sort: true,
    is_filter: false,
    position: 13,
  },
  {
    width: 200,
    title: 'Certification',
    dataIndex: 'certification',
    key: 'certification',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 14,
  },
  {
    width: 200,
    title: 'Created By',
    dataIndex: 'created_by',
    key: 'created_by',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 15,
  },
  {
    width: 200,
    title: 'Currency Code',
    dataIndex: 'ctc_currency_code',
    key: 'ctc_currency_code',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 16,
  },
  {
    width: 200,
    title: 'Payment Type',
    dataIndex: 'ctc_payment_type',
    key: 'ctc_payment_type',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 17,
  },
  {
    width: 200,
    title: 'Department',
    dataIndex: 'department',
    key: 'department',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 18,
  },
  {
    width: 200,
    title: 'Education Details',
    dataIndex: 'education_details',
    key: 'education_details',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 19,
  },
  {
    width: 200,
    title: 'Job Type',
    dataIndex: 'job_type',
    key: 'job_type',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 20,
  },
  {
    width: 200,
    title: 'Locations',
    dataIndex: 'locations',
    key: 'locations',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 21,
  },
  {
    width: 150,
    title: 'Minimum Ctc',
    dataIndex: 'minimum_ctc',
    key: 'minimum_ctc',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 22,
    align: 'center',
  },
  {
    width: 150,
    title: 'Maximum ctc',
    dataIndex: 'maximum_ctc',
    key: 'maximum_ctc',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 23,
    align: 'center',
  },
  // {
  //   width: 200,
  //   title: 'Minimum Experience',
  //   dataIndex: 'minimum_experience',
  //   key: 'minimum_experience',
  //   fieldShow: false,
  //   isExpanded: false,
  //   is_search: true,
  //   is_sort: true,
  //   is_filter: false,
  //   position: 21,
  // },
  // {
  //   width: 200,
  //   title: 'Maximum Experience',
  //   dataIndex: 'maximum_experience',
  //   key: 'maximum_experience',
  //   fieldShow: false,
  //   isExpanded: false,
  //   is_search: true,
  //   is_sort: true,
  //   is_filter: false,
  //   position: 22,
  // },
  {
    width: 200,
    title: 'Ageing',
    dataIndex: 'posted',
    key: 'posted',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 24,
  },
  {
    width: 200,
    title: 'Required Skill',
    dataIndex: 'required_skill',
    key: 'required_skill',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 25,
  },
  {
    width: 200,
    title: 'Preferred Skill',
    dataIndex: 'preferred_skill',
    key: 'preferred_skill',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 26,
  },
  {
    width: 200,
    title: 'Gender',
    dataIndex: 'gender',
    key: 'gender',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 27,
  },
  {
    width: 200,
    title: 'Notice Period',
    dataIndex: 'notice_period',
    key: 'notice_period',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 28,
  },
  {
    width: 200,
    title: 'Visa Required',
    dataIndex: 'visa_required',
    key: 'visa_required',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 29,
  },
  {
    width: 200,
    title: 'Internal Hire',
    dataIndex: 'internal_hire',
    key: 'internal_hire',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 30,
  },
  {
    width: 200,
    title: 'Reference type',
    dataIndex: 'reference_type',
    key: 'reference_type',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 31,
  },
  {
    width: 200,
    title: 'Relocation Required',
    dataIndex: 'ready_to_relocate',
    key: 'ready_to_relocate',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 32,
  },
  {
    width: 200,
    title: 'Driving license required',
    dataIndex: 'driving_license_required',
    key: 'driving_license_required',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 33,
  },
  {
    width: 200,
    title: 'Languages Preferred',
    dataIndex: 'languages_preferred',
    key: 'languages_preferred',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 34,
  },
  {
    width: 200,
    title: 'Nationality',
    dataIndex: 'nationality',
    key: 'nationality',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 35,
  },
  {
    width: 200,
    title: 'Recruiters',
    dataIndex: 'recruiters',
    key: 'recruiters',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 36,
  },
  {
    width: 200,
    title: 'Clients',
    dataIndex: 'clients',
    key: 'clients',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 37,
  },
  {
    width: 200,
    title: 'Consultants',
    dataIndex: 'consultants',
    key: 'consultants',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 38,
  },
  {
    width: 200,
    title: 'Interviews',
    dataIndex: 'interviews',
    key: 'interviews',
    fieldShow: false,
    isExpanded: false,
    is_sort: true,
    is_search: false,
    is_filter: true,
    position: 39,
  },
  {
    width: 200,
    title: 'Interviewers',
    dataIndex: 'interviewers',
    key: 'interviewers',
    fieldShow: false,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 40,
  },
  {
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 41,
    className: 'ant-table-column-action',
  },
];
export const interviewColumns = [
  {
    width: 200,
    title: 'Name',
    dataIndex: 'full_name',
    key: 'full_name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },

  // {
  //   width: 200,
  //   title: 'Last Name',
  //   dataIndex: 'last_name',
  //   key: 'last_name',
  //   fieldShow: true,
  //   isExpanded: false,
  //   is_search: true,
  //   is_sort: true,
  //   is_filter: false,
  //   position: 1,
  //   fixed: '',
  // },
  {
    width: 200,
    title: 'Interviewer',
    dataIndex: 'interviewers',
    key: 'interviewers',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },
  {
    width: 200,
    title: 'Interview Date',
    dataIndex: 'interview_start_date',
    key: 'interview_start_date',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 2,
    fixed: '',
  },
  {
    width: 200,
    title: 'Interview Time',
    dataIndex: 'interview_start_time',
    key: 'interview_start_time',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 3,
    fixed: '',
  },
  {
    width: 200,
    title: 'Interview Type',
    dataIndex: 'interview_type',
    key: 'interview_type',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 4,
    fixed: '',
  },

  {
    width: 200,
    title: 'Job',
    dataIndex: 'job',
    key: 'job',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 5,
    fixed: '',
  },

  {
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 6,
    className: 'ant-table-column-action',
  },
];
export const registartionColumns = [
  {
    width: 200,
    title: 'Comapny Name',
    dataIndex: 'name',
    key: 'name',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 0,
    fixed: '',
  },

  {
    width: 200,
    title: 'Created Date',
    dataIndex: 'created',
    key: 'created',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    position: 1,
    fixed: '',
  },

  {
    width: 150,
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    fieldShow: true,
    isExpanded: false,
    is_search: false,
    is_sort: false,
    fixed: 'right',
    position: 2,
    className: 'ant-table-column-action',
  },
];
export const setDefaultColumn = (jobColumns, table_name) => {
  let defaultColumn = jobColumns?.map((col) => {
    if (
      (table_name === 'HOLD' && col?.dataIndex === 'created') ||
      (table_name === 'PENDING' &&
        (col?.dataIndex === 'created' ||
          col?.dataIndex === 'reviewers')) ||
      (table_name === 'DRAFT' && col?.dataIndex === 'created') ||
      (table_name === 'QUESTIONAIRE' && col?.dataIndex === 'created') ||
      ((table_name === 'CLOSED' || table_name === 'FORCEFULLY_CLOSED') &&
        (col?.dataIndex === 'created' ||
          col?.dataIndex === 'total_offered' ||
          col?.dataIndex === 'total_hired'))
    ) {
      return { ...col, fieldShow: true };
    }
    return col;
  });
  return defaultColumn;
};
const insertColumnAtIndex = (columns, newColumn, index) => {
  return [...columns.slice(0, index), newColumn, ...columns.slice(index)];
};
const defaultRevewPendingColumn = insertColumnAtIndex(
  interviewColumns,
  {
    width: 200,
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    fieldShow: true,
    isExpanded: false,
    is_search: true,
    is_sort: true,
    is_filter: false,
    //position: 21,
    fixed: '',
  },
  6,
);

export const SCHEDULES_PREFERENCE_KEY = 'SCHEDULES';
export const COMMUNICATION_REPORT_KEY = 'GENERAL';
export const COMMUNICATION_PREFERENCE_KEY = 'COMMUNICATION';
export const SOURCING_REPORT_KEY = 'SOURCING';
export const SCREENING_REPORT_KEY = 'SCREENING';
export const SCHEDULE_REQUESTS_PREFERENCE_KEY = 'SCHEDULE_REQUESTS_TAB';
export const CANDIDATES_REPORT_PREFERENCE_KEY = 'CANDIDATES_REPORT';
export const LICENSES_PREFERENCE_KEY = 'LICENSE';
export const ENQUIRIES_PREFERENCE_KEY = 'ENQUIRIES';
export const CANDIDATES_SUB_PREFERENCE_KEY = 'CANDIDATES_SUB';
export const CANDIDATES_MAIN_PREFERENCE_KEY = 'CANDIDATES_MAIN';

export const column_prefereces = {
  [SCHEDULES_PREFERENCE_KEY]: {
    GENERAL: jobDetailsScheduleColumnConfig,
  },
  [CANDIDATES_SUB_PREFERENCE_KEY]: {
    [CANDIDATE_TAB_CONSTANTS.PIPELINE]: candidatesSubTableConfig,
    [CANDIDATE_TAB_CONSTANTS.RECENT]: candidatesSubTableConfig,
    [CANDIDATE_TAB_CONSTANTS.CONFLICTED]: candidatesSubTableConfig,
    [CANDIDATE_TAB_CONSTANTS.SUGGESTED]: mainCandidatesTableConfig,
    [CANDIDATE_TAB_CONSTANTS.REJECTED]: candidatesSubTableConfig,
  },
  [COMMUNICATION_PREFERENCE_KEY]: {
    [COMMUNICATION_REPORT_KEY]: communicationReportTableConfig,
  },
  [LICENSES_PREFERENCE_KEY]: {
    GENERAL: licenseTableConfig,
  },
  [ENQUIRIES_PREFERENCE_KEY]: {
    GENERAL: enquiriesTableConfig,
  },
  [SCHEDULE_REQUESTS_PREFERENCE_KEY]: {
    PRESCREEN: scheduleRequestsPrescreeningTableConfig,
    GENERAL: scheduleReportsInterviewTableConfig,
  },
  [CANDIDATES_REPORT_PREFERENCE_KEY]: {
    [SOURCING_REPORT_KEY]: sourcingReportTableConfig,
    [SCREENING_REPORT_KEY]: screeningReportTableConfig,
  },
  [CANDIDATES_MAIN_PREFERENCE_KEY]: {
    [CANDIDATE_TAB_CONSTANTS.FINALIZED]: mainCandidatesTableConfig,
    [CANDIDATE_TAB_CONSTANTS.CONFLICTED]: mainCandidatesTableConfig,
    [CANDIDATE_TAB_CONSTANTS.PIPELINE]: pipelineComponentColumnData,
    [CANDIDATE_TAB_CONSTANTS.OFFERED]: pipelineComponentColumnData,
    [CANDIDATE_TAB_CONSTANTS.REJECTED]: pipelineComponentColumnData,
    [CANDIDATE_TAB_CONSTANTS.NOSHOW]: noShowCandidatesColumns,
  },
  JOBPREFERENCE: {
    ACTIVE: setDefaultColumn(jobColumns, 'ACTIVE'),
    PENDING: setDefaultColumn(jobColumns, 'PENDING'),
    OVERDUE: setDefaultColumn(jobColumns, 'OVERDUE'),
    DRAFT: setDefaultColumn(jobColumns, 'DRAFT'),
    HOLD: setDefaultColumn(jobColumns, 'HOLD'),
    CLOSED: setDefaultColumn(jobColumns, 'CLOSED'),
    ONBOARDING_IN_PROGRESS: setDefaultColumn(
      jobColumns,
      'ONBOARDING_IN_PROGRESS',
    ),
    QUESTIONAIRE: setDefaultColumn(jobColumns, 'QUESTIONAIRE'),
    ALL: setDefaultColumn(jobColumns, 'ALL'),
  },

  SCORECARDPREFERENCE: {
    PRESCREEN: prescreenColumns,
    LEVELS: levelsColumns,
  },
  OFFEREDREJECTEDPREFERENCE: {
    OFFERED: tableOfferColumn,
    REJECTED: tableRejectColumn,
  },
  INTERVIEWPREFERENCE: {
    ALL_INTERVIEWS: interviewColumns,
    CANCELLED: interviewColumns,
    REVIEW_COMPLETED: interviewColumns,
    REVIEW_PENDING: defaultRevewPendingColumn,
    UPCOMING: interviewColumns,
  },
  SETTINGS: {
    DEPARTMENTS: departmentsColumns,
    SKILLS: skillColumns,
    EVALUATION_PARAMETER: evaluationParameterColumns,
    TEAM_MEMBER_INVITATION: teamMemberColumns,
    PROFILEANDROLES: profileRoleColumn,
    LOCATIONS: locationColumn,
    EDUCATION_DETAILS: educationDetailsColumn,
    HIRING_TYPE: hiringTypeColumns,
    CERTIFICATION: certificationColumn,
    EVALUATION_QUESTIONNAIRE: evaluationQuestionnaireColumn,
    CONSULTANT: consultanyColumns,
    PRESCREEN_PARAMETER: prescreenParameterColumn,
    CONSULTANCY_CLIENT: consultancyClientColumn,
    CONSULTANCY_USER: consultancyUserColumn,
    DOCUMENT_TYPE: documentTypeColumns,
  },
  QUESTIONBANKPREFERENCE: {
    QUESTIONLIST: questionnaireColumns,
  },
  REGISTRATIONPREFERENCE: {
    REGISTRATIONLIST: registartionColumns,
  },
};

export const UpdatePersistedStore = (
  column_preferece,
  sortedColumns,
  table_name,
  preference,
) => {
  const updatedPreferences = JSON.parse(JSON.stringify(column_preferece));
  if (
    updatedPreferences[preference] &&
    updatedPreferences[preference][table_name]
  ) {
    updatedPreferences[preference][table_name] = sortedColumns;
  } else {
    console.warn(
      `Invalid preference or table_name: ${preference}, ${table_name}`,
    );
  }
  return updatedPreferences;
};

export const isColPreferenceChanged = (defaultColumns, storedColumns) => {
  if (defaultColumns?.length !== storedColumns?.length) {
    return true;
  }

  for (let i = 0; i < defaultColumns.length; i++) {
    if (defaultColumns[i].dataIndex !== storedColumns[i].dataIndex) {
      return true;
    }
  }

  return false;
};
