import { useMutation, useQuery } from '@tanstack/react-query';
import settingService from 'Services/settingsService';
import { DEFAULT_QUERY_ARGS } from 'Utilities/constants';
import { createDownloadLink } from 'Utilities/utils';

/* 
  For fetching role permissions of a perticulr role
*/
const useGetProfileAndRolePermissions = (config = DEFAULT_QUERY_ARGS) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['rolesAndPermissions'],
    queryFn: async () => {
      const details = await settingService.getProfileAndRoleById(
        null,
        paramsData?.roleId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};

/* 
  For fetching all available permissions for usage in add roles scenerio
*/
const useGetAllAvailableProfileAndRolePermissions = (
  config = DEFAULT_QUERY_ARGS,
) => {
  const { ...queryProps } = config;
  const query = useQuery({
    queryKey: ['allAvailableRolesAndPermissions'],
    queryFn: async () => {
      const details =
        await settingService.getProfileAndRolePermissionData();
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};

/* 
  For creating new role
*/
const usePostNewProfileAndRolePermissions = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (body) => {
      const details = await settingService.addNewRolesAndPermissions(body);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};

/* 
  For updating a role
*/
const useUpdateProfileAndRolePermissions = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { roleId, ...body } = params;
      const details = await settingService.editProfileAndRole(
        body,
        null,
        roleId,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};

const useGetDepartment = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getDepartmentList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getDepartmentsData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};
const useCreateDepartment = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (body) => {
      const details = await settingService.addNewDepartments(body);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useUpdaetDepartment = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      console.log('testdata', params);
      const { data, departmentId } = params;
      const details = await settingService.editDepartments(
        data,
        departmentId,
      );
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useDepartment = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['departments', paramsData?.departmentId],
    queryFn: async () => {
      const details = await settingService.getDepartmentById(
        paramsData?.departmentId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useGetSkills = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getSkillList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response = await settingService.getSkillData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};
const useSkill = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['departments', paramsData?.skillId],
    queryFn: async () => {
      const details = await settingService.getskillInfo(
        paramsData?.skillId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useCreateSkill = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (body) => {
      const details = await settingService.addSkill(body);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useUpdateSkill = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      console.log('testdata', params);
      const { data, skillId } = params;
      const details = await settingService.editSkill(data, skillId);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useDocumentType = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['documentName', paramsData?.documentId],
    queryFn: async () => {
      const details = await settingService.getDocuemntType(
        paramsData?.documentId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useCreateDocuments = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (body) => {
      const details = await settingService.addDocument(body);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useUpdateDocument = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      console.log('testdata', params);
      const { data, documentId } = params;
      const details = await settingService.editDocument(data, documentId);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useCreateGroup = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (body) => {
      const details = await settingService.addGroup(body);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useGroupById = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['groupById', paramsData?.groupId],
    queryFn: async () => {
      const details = await settingService.getGroupById(
        paramsData?.groupId,
      );
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useGetGroup = (config) => {
  const { paramsData = {}, ...queryProps } = config;
  const query = useQuery({
    queryKey: ['group'],
    queryFn: async () => {
      const details = await settingService.getGroup();
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
const useUpdateGroup = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (params) => {
      const { data, groupId } = params;
      const details = await settingService.updateGroup(data, groupId);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const useCreateContact = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (body) => {
      const details = await settingService.addContact(body);
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
const DOWNLOAD_SAMPLE_CONTACT_FILE = 'DOWNLOAD_SAMPLE_CONTACT_FILE';
const useGetSampleContactFile = (config) => {
  const { onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [DOWNLOAD_SAMPLE_CONTACT_FILE],
    queryFn: async () => {
      const response = await settingService.getSampelContactFile();
      const url = response?.data?.data?.sample_excel_format;
      console.log('url', url);
      createDownloadLink(url);
    },
    onSuccess,
    enabled: false,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });

  return query;
};

const useGetSampleGroupFile = (config) => {
  const { onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: ['DOWNLOAD_SAMPLE_GROUP_FILE'],
    queryFn: async () => {
      const response = await settingService.getSampelGroupFile();
      const url = response?.data?.data?.sample_excel_format;
      console.log('url', url);
      createDownloadLink(url);
    },
    onSuccess,
    enabled: false,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });

  return query;
};
const useGetEvaluationParameter = ({
  params = {},
  filter = '',
  ...rest
}) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getEvaluationParameterList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getEvaluationParameter(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};
const useGetTeamMemberList = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getTeaminvitationList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getTeamMembersData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useGetProfileRole = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getProfileRoleList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getProfileRolesData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useGetLocation = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getLocationList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response = await settingService.getLocationData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useGetEducation = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getEducationList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response = await settingService.getEducationData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useGetCertification = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getCertificationList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getCertificateData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useGetEvaluationQuestionnaire = ({
  params = {},
  filter = '',
  ...rest
}) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getEvaluationQuestionnaireList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getEvaluationQuestionnaireData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};
const useGetHiringType = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getHiringList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response = await settingService.getHiringType(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};
const useGetConsultancy = ({ params = {}, filter = '', ...rest }) => {
  console.log('test', params?.search);
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getCounsultancyList',
      params?.limit,
      params?.offset,
      params?.is_consultant_or_client,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        is_consultant_or_client: params?.is_consultant_or_client,
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getConsultancyData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};
const useGetPrescreenParameter = ({
  params = {},
  filter = '',
  ...rest
}) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getPrescreenParameterList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getPrescreenParameterData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useGetConsutancyClient = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getConsultancyClientList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getConsultancyClientData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useGetConsultancyUser = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getConsultancyUserList',
      params?.limit,
      params?.offset,
      params?.is_consultant_or_client,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        is_consultant_or_client: params?.is_consultant_or_client,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getConsultancyUserData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useGetDocumentType = ({ params = {}, filter = '', ...rest }) => {
  const filterPhrases = filter?.split('=');
  let filterKey;
  let term;
  if (Boolean(filter)) {
    [filterKey, term] = filterPhrases;
  }

  const query = useQuery({
    queryKey: [
      ' getDocumentTypeList',
      params?.limit,
      params?.offset,
      params?.search,
      params?.order_by,
      ...(Boolean(filter) ? filter : ''),
    ],
    queryFn: async () => {
      const queryParams = {
        limit: params?.limit,
        offset: params?.offset,
        ...(Boolean(filter) && { [filterKey]: term }),
        ...(Boolean(params?.search) && { search: params?.search }),
        ...(Boolean(params?.order_by) && {
          order_by: JSON.stringify(params?.order_by),
        }),
      };
      const response =
        await settingService.getdocumentTypeData(queryParams);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

const useUpdateQuestionnireActive = (config) => {
  const { onSuccess, onError } = config;
  const query = useMutation({
    mutationFn: async (params) => {
      const mutationResponse =
        await settingService.updateQuestionnireActive(params);
      return mutationResponse;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};

const useGetAvatarSettings = (config = DEFAULT_QUERY_ARGS) => {
  const { ...queryProps } = config;
  const query = useQuery({
    queryKey: ['avatarsettings'],
    queryFn: async () => {
      const details = await settingService.getAvatarSettings();
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryProps,
  });
  return query;
};
export {
  useGetProfileAndRolePermissions,
  useGetAllAvailableProfileAndRolePermissions,
  usePostNewProfileAndRolePermissions,
  useUpdateProfileAndRolePermissions,
  useCreateDepartment,
  useUpdaetDepartment,
  useDepartment,
  useSkill,
  useCreateSkill,
  useUpdateSkill,
  useDocumentType,
  useCreateDocuments,
  useUpdateDocument,
  useCreateGroup,
  useGetGroup,
  useGroupById,
  useUpdateGroup,
  useCreateContact,
  useGetSampleContactFile,
  useGetSampleGroupFile,
  useGetDepartment,
  useGetSkills,
  useGetEvaluationParameter,
  useGetTeamMemberList,
  useGetProfileRole,
  useGetLocation,
  useGetEducation,
  useGetCertification,
  useGetEvaluationQuestionnaire,
  useGetHiringType,
  useGetConsultancy,
  useGetPrescreenParameter,
  useGetConsutancyClient,
  useGetConsultancyUser,
  useGetDocumentType,
  useUpdateQuestionnireActive,
  useGetAvatarSettings,
};
