import { useMutation, useQuery } from '@tanstack/react-query';
import candidateService from 'Services/candidateService';
import CommunicationService from 'Services/communicationService';
import { isEmpty } from 'lodash';

/* 
  Hook for sending excel data and getting data back to show table
*/
const useCandidateExcelUpload = (config) => {
  const { onSuccess = () => {}, onError = () => {} } = config;
  const query = useMutation({
    mutationFn: async (file) => {
      const mutationResponse = await candidateService.excelUpload(file);
      return mutationResponse;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};

/* 
  Hook for sending resumes data
*/
const useCandidateResumesUpload = (config) => {
  const query = useMutation({
    mutationFn: async ({ data, params }) => {
      const mutationResponse = await candidateService.resumeUpload(
        data,
        params,
      );
      return mutationResponse;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

/* 
  Hook for posting excel data to server after showing table and mapping fields from table header
*/
const useCandidatesPostExcelData = (config) => {
  const { onSuccess, onError } = config;
  const query = useMutation({
    mutationFn: async (args) => {
      const { tableData, jobId } = args;
      const mutationResponse = await candidateService.postExcelData(
        tableData,
        jobId,
        !isEmpty(args?.params) ? args?.params : {},
      );
      return mutationResponse?.data?.data;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};

/* 
  Hook for Sending an Career Portal invitation mail to a Candidate 
*/
const useSendCandidateInvitationByMail = (config) => {
  const query = useMutation({
    mutationFn: async (body) => {
      const mutationResponse =
        await candidateService.sendCandidateInvitationByMail(body);
      return mutationResponse;
    },
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

/* 
  Hook for rejecting candidates
*/
const useRejectCandidates = (config) => {
  const { onSuccess, onError } = config;
  const query = useMutation({
    mutationFn: async (args) => {
      const { candidateIds, jobId } = args;
      const paramsData = {
        job_id: jobId,
        candidate_ids: candidateIds,
      };
      const mutationResponse =
        await candidateService.rejectCandidate(paramsData);
      return mutationResponse;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};

/* 
  For updating candidate
*/

const useUpdateCandidate = (config) => {
  const { onSuccess, onError } = config;
  const query = useMutation({
    mutationFn: async (args) => {
      const { paramsData, candidateId } = args;
      const mutationResponse =
        await candidateService.updateCandidateDetailsFetchedFromResume(
          paramsData,
          candidateId,
        );
      return mutationResponse?.data?.data;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};

/* 
  Adds a new import source to the sources list
*/
const useAddImportSource = (config) => {
  const query = useMutation({
    mutationFn: async (data) => {
      const response = await candidateService.addImportSource(data);
      return response?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

/* 
  Api for fetching import source list
*/
export const GET_IMPORT_SOURCE_LIST = 'GET_IMPORT_SOURCE_LIST';
const useGetImportSources = (config) => {
  const query = useQuery({
    queryKey: [GET_IMPORT_SOURCE_LIST],
    queryFn: async () => {
      const details = await candidateService.getImportSources();
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

/* 
  Gets all resumes based on chosen candidate
*/
const useGetAllCandidateResumes = (config) => {
  const { onSuccess, onError, onSettled } = config;
  const query = useMutation({
    mutationFn: async (paramsData) => {
      // const { paramsData } = args;
      const mutationResponse =
        await candidateService.getAllCandidatesResumes(paramsData);
      return mutationResponse?.data?.data;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
    onSettled,
  });
  return query;
};

/* 
  Deletes other resumes and keeps selected resume
*/
const useChoseCandidateResume = (config) => {
  const { onSuccess, onError } = config;
  const query = useMutation({
    mutationFn: async (paramsData) => {
      const mutationResponse =
        await candidateService.chooseCandidateResume(paramsData);
      return mutationResponse;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};

/* 
  Api for fetching candidates list
*/
export const fullCandidatesListQueryName = 'fullCandidatesList';
const useCandidatesJobList = (config) => {
  const { isSuggestedTab, params, filter, ...rest } = config;

  if (Boolean(filter)) {
    const filterPhrases = filter?.split('=');
    const [filterKey, term] = filterPhrases;
    params[filterKey] = term;
  }

  const query = useQuery({
    queryKey: [
      fullCandidatesListQueryName,
      params?.status,
      params?.offset,
      params?.limit,
      params?.job_id,
      params?.order_by,
      ...[Boolean(filter) ? filter : null],
      ...[Boolean(params?.search) ? params?.search : null],
      ...[isSuggestedTab ? 'recommended' : null],
    ],
    queryFn: async () => {
      if (isSuggestedTab) {
        params.is_recommended = true;
      }
      const details = await candidateService.getCandidatesList(params);
      return details?.data?.data;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  Hook that fetches the list content of the Candidate page's Tabs: Offered, Rejected, Pipeline
*/
export const GET_CANDIDATE_PIPELINE_LIST = 'GET_CANDIDATE_PIPELINE_LIST';
const useGetCandidatesPipelineList = (config) => {
  const { params, filter, ...rest } = config;
  if (Boolean(filter)) {
    const filterPhrases = filter?.split('=');
    const [filterKey, term] = filterPhrases;
    params[filterKey] = term;
  }

  const query = useQuery({
    queryKey: [
      GET_CANDIDATE_PIPELINE_LIST,
      filter,
      params?.limit,
      params?.offset,
      params?.status,
      params?.search,
      params?.order_by,
    ],
    queryFn: async () => {
      const details =
        await candidateService.getCandidatePipelineList(params);
      return details?.data?.data;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  API for sending prescreening invite
*/
const usePreeScreening = (config) => {
  const { onSuccess, onError } = config;
  const query = useMutation({
    mutationFn: async ({ body, params = {} }) => {
      console.log(body, 'paramsbody');
      console.log(params, 'params');
      const mutationResponse = await candidateService.sendToPreScreening(
        body,
        params,
      );
      return mutationResponse?.data?.data;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};

/* 
  Gets Nationalities for the Entry Types
*/
export const GET_NATIONALITIES = 'GET_NATIONALITIES';
export const useGetNationalities = (config) => {
  const { params } = config;
  const query = useQuery({
    queryKey: [GET_NATIONALITIES, params?.search],
    queryFn: async () => {
      const param = {
        ...(params?.search && { search: params?.search }),
      };
      const details = await candidateService.getNationalities(param);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

/* 
  Gets Known Languages for the Entry Types
*/
export const GET_KNOWN_LANGUAGES = 'GET_KNOWN_LANGUAGES';
export const useGetKnownLanguages = (config) => {
  const { params } = config;
  const query = useQuery({
    queryKey: [GET_KNOWN_LANGUAGES, params?.search],
    queryFn: async () => {
      const param = {
        ...(params?.search && { search: params?.search }),
      };
      const details = await candidateService.getKnownLanguages(param);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...config,
  });
  return query;
};

/* 
  Fetches all associated documents of a candidate
*/
export const FETCH_ALL_DOCUMENTS = 'FETCH_ALL_DOCUMENTS';
export const useFetchAllDocuments = (config) => {
  const { params, ...rest } = config;
  const query = useQuery({
    queryKey: [FETCH_ALL_DOCUMENTS, params?.candidate_id],
    queryFn: async () => {
      const details = await candidateService.getDocuments(params);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  Uploads a new document of a candidate
*/
export const useUploadNewDocument = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, params = {} }) => {
      const details = await candidateService.uploadDocument(body, params);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

/* 
  Deletes a Candidate's document.
*/
export const useDeleteChosenDocument = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ id, params = {} }) => {
      const details = await candidateService.deleteDocument(id, params);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

/* 
  Approves a document 
*/
export const useConfirmChosenDocuments = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, params }) => {
      const details = await candidateService.confirmDocument(body, params);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

/* 
  Fetches all supported documents types
*/
export const FETCH_ALL_DOCUMENTS_TYPES = 'FETCH_ALL_DOCUMENTS_TYPES';
export const useFetchAllDocumentTypes = (config) => {
  const { params, ...rest } = config;
  const query = useQuery({
    queryKey: [FETCH_ALL_DOCUMENTS_TYPES],
    queryFn: async () => {
      const details = await candidateService.getDocumentTypes();
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  Api for fetching candidates list
*/
const useCandidateInfo = (config) => {
  const { paramsData, onSuccess = () => null, ...queryConfigs } = config;
  const queryKey = [paramsData?.candidateId];
  const query = useQuery({
    queryKey: ['candidateInfo', queryKey],
    queryFn: async () => {
      const details = await candidateService.getCandidateInfo(
        paramsData?.candidateId,
      );
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

// get candidate resume

const useCandidateResume = (config) => {
  const { paramsData, onSuccess = () => null, ...queryConfigs } = config;
  const queryKey = [paramsData?.candidateId];
  const query = useQuery({
    queryKey: ['candidateResume', queryKey],
    queryFn: async () => {
      const details = await candidateService.getCandidateResume(
        paramsData?.candidateId,
      );
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
Get candidates Applied jobs
*/
const FETCH_CANDIDATE_APPLIED_JOBS = 'FETCH_CANDIDATE_APPLIED_JOBS';
const useCandidateAppliedJobs = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const queryKey = [params?.candidate, params?.offset, params?.limit];
  const query = useQuery({
    queryKey: [FETCH_CANDIDATE_APPLIED_JOBS, queryKey],
    queryFn: async () => {
      const details =
        await candidateService.getCandidateAppliedJobs(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
 Get candidates jobs that are preferred
*/
const useGetPreferredJobsForCandidates = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const queryKey = [params?.candidate];
  const query = useQuery({
    queryKey: ['preferredJobsForCandidates', queryKey],
    queryFn: async () => {
      const details =
        await candidateService.getPreferredCandidateJobs(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
  Manually adds a new single candidate through a form.
*/
const useCreateNewCandidate = (config) => {
  const mutation = useMutation({
    mutationFn: async ({ body, params = {} }) => {
      const details = await candidateService.createCandidate(body, params);
      return details?.data;
    },
    ...config,
  });
  return mutation;
};

/* 
  View Candidate > Adds new experience to the Candidate
*/
const useAddCandidateExperience = (config) => {
  const {
    onError = () => null,
    onSuccess = () => null,
    ...queryConfigs
  } = config;
  const mutation = useMutation({
    mutationFn: async (data) => {
      const details = await candidateService.addCandidateExperience(data);
      return details?.data?.data;
    },
    onSuccess,
    onError,
    ...queryConfigs,
  });
  return mutation;
};

/* 
  View Candidate > Edits existing experience of the chosen Candidate
*/
const useEditCandidateExperience = (config) => {
  const {
    onError = () => null,
    onSuccess = () => null,
    ...queryConfigs
  } = config;
  const mutation = useMutation({
    mutationFn: async (data) => {
      const details = await candidateService.editCandidateExperience(data);
      return details?.data?.data;
    },
    onSuccess,
    onError,
    ...queryConfigs,
  });
  return mutation;
};

/* 
  View Candidate > Deleting chosen experience of the Candidate
*/
const useDeleteCandidateExperience = (config) => {
  const {
    onError = () => null,
    onSuccess = () => null,
    ...queryConfigs
  } = config;
  const mutation = useMutation({
    mutationFn: async (data) => {
      const details =
        await candidateService.deleteCandidateExperience(data);
      return details?.data?.data;
    },
    onSuccess,
    onError,
    ...queryConfigs,
  });
  return mutation;
};

/* 
  Job Details > Candidate > Fetches data grid content 
  for the 'Consultancy Candidates' tab
*/
export const GET_CONSULTANCY_CANDIDATE_LIST =
  'GET_CONSULTANCY_CANDIDATE_LIST';
const useGetConsultancyCandidatesList = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [
      GET_CONSULTANCY_CANDIDATE_LIST,
      params?.limit,
      params?.offset,
      params?.job_id,
      params?.search,
    ],
    queryFn: async () => {
      const details =
        await candidateService.getConsultancyCandidateList(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
  Job Details > Candidate > Schedules a candidate for 
  the prescreening phase and the specific interview phase.
*/
const useScheduleConsultancyCandidate = (config) => {
  const {
    onError = () => null,
    onSuccess = () => null,
    ...queryConfigs
  } = config;
  const mutation = useMutation({
    mutationFn: async ({ body, params }) => {
      const details =
        await candidateService.setConsultancyCandidateSchedule(
          body,
          params,
        );
      return details?.data?.data;
    },
    onSuccess,
    onError,
    ...queryConfigs,
  });
  return mutation;
};

/* 
  View Candidate > Adds new a Project field to the Candidate
*/
const useAddCandidateProject = (config) => {
  const {
    onError = () => null,
    onSuccess = () => null,
    ...queryConfigs
  } = config;
  const mutation = useMutation({
    mutationFn: async (data) => {
      const details = await candidateService.addCandidateProject(data);
      return details?.data?.data;
    },
    onSuccess,
    onError,
    ...queryConfigs,
  });
  return mutation;
};

/* 
  View Candidate > Edits an existing project of the chosen Candidate
*/
const useEditCandidateProject = (config) => {
  const {
    onError = () => null,
    onSuccess = () => null,
    ...queryConfigs
  } = config;
  const mutation = useMutation({
    mutationFn: async (data) => {
      const details = await candidateService.editCandidateProject(data);
      return details?.data?.data;
    },
    onSuccess,
    onError,
    ...queryConfigs,
  });
  return mutation;
};

/* 
  View Candidate > Deleting chosen project of the Candidate
*/
const useDeleteCandidateProject = (config) => {
  const {
    onError = () => null,
    onSuccess = () => null,
    ...queryConfigs
  } = config;
  const mutation = useMutation({
    mutationFn: async (data) => {
      const details = await candidateService.deleteCandidateProject(data);
      return details?.data?.data;
    },
    onSuccess,
    onError,
    ...queryConfigs,
  });
  return mutation;
};

/* 
  Candidate Reports > Sourcing Report. Fetches the page metrics 
  to represent source statistics. 
*/
export const FETCH_SOURCING_STATISTICS_REPORT =
  'FETCH_SOURCING_STATISTICS_REPORT';
const useGetSourcingStatisticsReport = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [
      params?.job,
      params?.end_date,
      params?.start_date,
      FETCH_SOURCING_STATISTICS_REPORT,
    ],
    queryFn: async () => {
      const details = await candidateService.getSourcingReport(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

// api for grid view sourcing report
export const FETCH_SOURCING_REPORT = 'FETCH_SOURCING_REPORT';
const useGetSourcingReport = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [
      params?.job,
      params?.end_date,
      params?.start_date,
      params?.limit,
      params?.offset,
      params?.search,
      FETCH_SOURCING_REPORT,
    ],
    queryFn: async () => {
      const details = await candidateService.getSourcingReportGrid(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

// api for grid view screening report
export const FETCH_SCREENING_GRID_REPORT = 'FETCH_SCREENING_GRID_REPORT';
const useGetScreeningGridReport = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [
      params?.job,
      params?.end_date,
      params?.start_date,
      params?.page,
      params?.pageSize,
      params?.offset,
      params?.search,
      FETCH_SCREENING_GRID_REPORT,
    ],
    queryFn: async () => {
      const details =
        await candidateService.getScreeningGridReport(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

// api for export grid view sourcing report
export const FETCH_SOURCING_GRID_DOWNLOAD_REPORT =
  'FETCH_SOURCING_GRID_DOWNLOAD_REPORT';
const useGetSourcingGridReportExport = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [
      params?.job,
      params?.end_date,
      params?.start_date,
      params?.search,
      FETCH_SOURCING_GRID_DOWNLOAD_REPORT,
    ],
    queryFn: async () => {
      const details =
        await candidateService.getSourcingGridReportExport(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
  Candidate Reports > Sourcing Report. Fetches the page metrics 
  to represent candidates' pipeline statistics. 
*/
export const FETCH_SOURCE_PIPELINE_REPORT = 'FETCH_SOURCE_PIPELINE_REPORT';
const useGetSourcePipelineReport = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [
      params?.job,
      params?.end_date,
      params?.start_date,
      FETCH_SOURCE_PIPELINE_REPORT,
    ],
    queryFn: async () => {
      const details =
        await candidateService.getSourcePipelineReport(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
  Candidate Reports > Sourcing Report. Fetches the page metrics 
  to represent candidates average hiring times per source. 
*/
export const FETCH_SOURCE_AVERAGE_HIRING_REPORT =
  'FETCH_SOURCE_AVERAGE_HIRING_REPORT';
const useGetSourceAverageHiringTeamReport = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [
      params?.job,
      params?.end_date,
      params?.start_date,
      FETCH_SOURCE_AVERAGE_HIRING_REPORT,
    ],
    queryFn: async () => {
      const details =
        await candidateService.getSourceAverageHiringTimeReport(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

/* 
  Candidate Reports > Screening Report. Fetches the page metrics 
  to represent screening level results.
*/
export const FETCH_SCREENING_REPORT = 'FETCH_SCREENING_REPORT';
const useGetScreeningReport = (config) => {
  const { params, onSuccess = () => null, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [
      FETCH_SCREENING_REPORT,
      params?.end_date,
      params?.start_date,
      params?.job,
    ],
    queryFn: async () => {
      const details = await candidateService.getScreeningReport(params);
      return details?.data?.data;
    },
    onSuccess,
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

// fetch no show list
export const GET_NO_SHOW_LIST = 'GET_NO_SHOW_LIST';
export const useGetNoShowList = (config) => {
  const { params, filter, ...rest } = config;
  if (Boolean(filter)) {
    const filterPhrases = filter?.split('=');
    const [filterKey, term] = filterPhrases;
    params[filterKey] = term;
  }
  const query = useQuery({
    queryKey: [
      GET_NO_SHOW_LIST,
      params?.status,
      params?.search,
      params?.offset,
      params?.limit,
      params?.order_by,
      filter,
    ],
    queryFn: async () => {
      const details = await candidateService.getNoShowList(params, filter);
      return details?.data?.data;
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...rest,
  });
  return query;
};

/* 
  View Candidate > Jobs. Apply for a Preferred Job.
*/
const useApplyForPreferredJob = (config) => {
  const {
    onError = () => null,
    onSuccess = () => null,
    ...queryConfigs
  } = config;
  const mutation = useMutation({
    mutationFn: async (data) => {
      const details = await candidateService.applyForPreferredJob(data);
      return details?.data?.data;
    },
    onSuccess,
    onError,
    ...queryConfigs,
  });
  return mutation;
};

/* 
  Hook for holding a candidate
*/
const useHoldCandidates = (config) => {
  const { onSuccess, onError } = config;
  const query = useMutation({
    mutationFn: async (args) => {
      const { candidateIds, jobId } = args;
      const paramsData = {
        job_id: jobId,
        candidate_ids: candidateIds,
      };
      const mutationResponse =
        await candidateService.holdCandidate(paramsData);
      return mutationResponse;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};

const useGetCandidatesById = (config) => {
  const { params, ...queryConfigs } = config;
  const query = useQuery({
    queryKey: [
      'fetchCandidatesById',
      params?.status,
      params?.search,
      params?.job_id,
    ],
    queryFn: async () => {
      const details =
        await CommunicationService.getCandidatesByJobId(params);
      return details?.data?.data;
    },
    refetchOnWindowFocus: false,
    ...queryConfigs,
  });
  return query;
};

const useCandidateVideoUpload = (config) => {
  const { onSuccess, onError } = config;
  const query = useMutation({
    mutationFn: async ({ file, cId }) => {
      const params = { candidateId: cId };
      const mutationResponse = await candidateService.candidateVideoUpload(
        file,
        params,
      );
      return mutationResponse;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};

const useDeleteCandidateVideoUpload = (config) => {
  const { onSuccess, onError } = config;
  const query = useMutation({
    mutationFn: async ({ cId }) => {
      const params = { candidateId: cId };
      const mutationResponse =
        await candidateService.deleteCandidateVideoUpload(params);
      return mutationResponse;
    },
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
  });
  return query;
};
/* 
  Hook for downloading candidates resumes
*/
const useDownloadCandidateResumes = (config) => {
  const query = useMutation({
    mutationFn: async (body) => {
      const mutationResponse =
        await candidateService.downloadCandidateResumesByMail(body);
      return mutationResponse;
    },
    refetchOnWindowFocus: false,
    ...config,
  });

  return query;
};

export {
  useCandidateExcelUpload,
  useCandidateResumesUpload,
  useCandidatesPostExcelData,
  useRejectCandidates,
  useUpdateCandidate,
  useGetAllCandidateResumes,
  useAddImportSource,
  useGetImportSources,
  useChoseCandidateResume,
  useCandidatesJobList,
  useGetCandidatesPipelineList,
  usePreeScreening,
  useCandidateInfo,
  useCandidateAppliedJobs,
  useScheduleConsultancyCandidate,
  useGetPreferredJobsForCandidates,
  useHoldCandidates,
  useGetCandidatesById,
  useGetSourcePipelineReport,
  useGetSourcingStatisticsReport,
  useGetSourceAverageHiringTeamReport,
  useGetScreeningReport,
  useCandidateVideoUpload,
  useCreateNewCandidate,
  useAddCandidateProject,
  useEditCandidateProject,
  useApplyForPreferredJob,
  useDeleteCandidateProject,
  useAddCandidateExperience,
  useEditCandidateExperience,
  useDeleteCandidateExperience,
  useDeleteCandidateVideoUpload,
  useGetConsultancyCandidatesList,
  useSendCandidateInvitationByMail,
  useCandidateResume,
  useGetSourcingReport,
  useGetScreeningGridReport,
  useGetSourcingGridReportExport,
  useDownloadCandidateResumes,
};
