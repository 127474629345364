import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Menu } from 'antd';

import 'Styles/SideNavigation.scss';
import yviLogo from '../../Assets/logo.png';
import toggle from '../../Assets/toggle.png';
import { FULL_MENU_LIST } from 'Utilities/constants';
import { usePersistedStore } from 'Store/persistedStore';
import useDeviceDetect from '../../Hooks/useDeviceDetect';
import { useAuthentication } from 'ServiceHooks/authHooks';
import Button from 'Components/CommonComponents/Button/Button';

const getItem = ({ menuName, menuKey, menuIcon, subMenu, menuType }) => ({
  key: menuKey,
  type: menuType,
  icon: menuIcon,
  label: menuName,
  children: subMenu ? subMenu?.map(getItem) : null,
});

const SideNavigation = (props) => {
  const navigate = useNavigate();
  const { menuItems, adminMenuItems, userDetails, firstPermittedTab } =
    useAuthentication();
  const [collapsed, toggleCollapsed] = useToggle(false);
  const isMobileDevice = useDeviceDetect();

  const { resetCurrentInterviewerTab } = usePersistedStore();

  const isSuperUser = userDetails?.is_superadmin === true;

  const currentMenuItems = isSuperUser ? adminMenuItems : menuItems;

  const items = currentMenuItems.map(getItem);

  return (
    <>
      {!isMobileDevice && (
        <div className="flex relative main-sidebar">
          <div
            className={` ${collapsed ? 'w-64 ' : 'w-16 p-3 '} 
            bg-secondaryBgMain h-screen relative transition-all duration-300 hidden sm:block overflow-y-auto scrollbar-hide`}
          >
            <div className="flex flex-col items-center justify-center">
              <Button
                type={'text'}
                className={`flex items-center justify-center space-x-3 ${collapsed ? 'mt-8' : ''} m-4`}
                onClick={() => navigate(firstPermittedTab)}
              >
                <img
                  src={yviLogo}
                  alt="yvi-logo"
                  className="yvi-logo w-4"
                />
                {collapsed && (
                  <span className="text-[#848484] text-xl font-bold">
                    YVI
                  </span>
                )}
              </Button>
              <Menu
                items={items}
                mode="inline"
                defaultSelectedKeys={['1']}
                inlineCollapsed={!collapsed}
                onClick={({ key }) => {
                  if (key === FULL_MENU_LIST.interviews.menuKey) {
                    resetCurrentInterviewerTab();
                  }
                  let link;
                  if (key === 'logo') {
                    link = firstPermittedTab;
                  } else if (key.includes('.')) {
                    const keyPath = key.split('.');
                    const keyLength = keyPath.length;
                    const [menu, subMenu] = keyPath;
                    const chosenMenu = currentMenuItems.find(
                      (item) => item.menuName === menu,
                    );
                    const chosenSubMenu = chosenMenu.subMenu.find(
                      (item) => item.menuName === subMenu,
                    );
                    link = chosenSubMenu.menuLink;
                    if (keyLength > 2) {
                      const innerMenu = keyPath[2];
                      link = chosenSubMenu.subMenu.find(
                        (item) => item.menuName === innerMenu,
                      ).menuLink;
                    }
                  } else {
                    const chosenMenu = currentMenuItems.find(
                      (item) => item.menuName === key,
                    );
                    link = chosenMenu.menuLink;
                  }
                  navigate(link);
                }}
              />
            </div>
          </div>
          <div className={'duration-300 bg-white'}>
            <img
              src={toggle}
              alt="sidenav-toggle"
              onClick={() => toggleCollapsed()}
              className={`absolute cursor-pointer top-9 -right-3 w-6 ${!collapsed && 'rotate-180'} z-10`}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SideNavigation;
