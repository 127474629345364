import { HiOutlineBriefcase } from 'react-icons/hi';
import { RiBallPenLine } from 'react-icons/ri';
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { AiOutlineSetting } from 'react-icons/ai';
import { PiPencilSimpleLine } from 'react-icons/pi';
import { GoPeople } from 'react-icons/go';
import { TbFolderQuestion } from 'react-icons/tb';
import { CiHeadphones } from 'react-icons/ci';
import { VscGraphLine } from 'react-icons/vsc';
import { TbLicense } from 'react-icons/tb';
import { LiaClipboardListSolid } from 'react-icons/lia';
import { PiClipboardText } from 'react-icons/pi';
import routes from 'Routes';
import dayjs from 'dayjs';

const INTERVIEW_SCHEDULE_STATUS_TYPES = {
  RESCHEDULED: 'RESCHEDULED',
  SCHEDULED: 'SCHEDULED',
};

const SCHEDULE_GRID_STATUS_COLOR_CODE = {
  UPCOMING: '#F29339',
  NOT_ATTENDED: '#E57878',
  COMPLETED: 'green',
};

const DEFAULT_DATE_FILTER_FORMAT = 'DD-MM-YYYY';

const DEFAULT_DATE_FILTER_OPTIONS = [
  {
    label: 'Today',
    value: {
      startDate: dayjs(new Date()).format(DEFAULT_DATE_FILTER_FORMAT),
      endDate: dayjs().add(1, 'day').format(DEFAULT_DATE_FILTER_FORMAT),
    },
  },
  {
    label: 'Last Week',
    value: {
      startDate: dayjs()
        .subtract(7, 'day')
        .format(DEFAULT_DATE_FILTER_FORMAT),
      endDate: dayjs(new Date()).format(DEFAULT_DATE_FILTER_FORMAT),
    },
  },
  {
    label: 'Coming Week',
    value: {
      startDate: dayjs(new Date()).format(DEFAULT_DATE_FILTER_FORMAT),
      endDate: dayjs().add(7, 'day').format(DEFAULT_DATE_FILTER_FORMAT),
    },
  },
  {
    label: 'Coming Month',
    value: {
      startDate: dayjs(new Date()).format(DEFAULT_DATE_FILTER_FORMAT),
      endDate: dayjs().add(30, 'day').format(DEFAULT_DATE_FILTER_FORMAT),
    },
  },
  {
    label: 'Custom Date',
    value: {
      startDate: 'startDate',
      endDate: 'endDate',
    },
  },
];

const INTERVIEW_TYPES = {
  F2F: 'F2F',
  LIVE: 'LIVE',
  AI_VIDEO: 'AI_VIDEO',
  TELEPHONIC: 'TELEPHONIC',
  PERSONALITY: 'PERSONALITY',
  AI_INTERVIEW_WITHOUT_VIDEO: 'AI_INTERVIEW_WITHOUT_VIDEO',
};

const DEFAULT_PAGINATION_CONFIG = {
  limit: 5,
  pageNumber: 1, // current page number, starts with page 1
  start: 0,
  end: 0,
  offset: 0,
  totalItems: 0,
};

const CANDIDATE_RESUME_STATUS = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  DUPLICATE: 'DUPLICATE',
  EXISTING: 'EXISTING',
  NO_STATUS: 'NO_STATUS',
};

const CANDIDATE_RESUME_STATUS_COLOR_CODES = {
  INVALID: 'red',
  DUPLICATE: 'yellow',
  NO_STATUS: 'blue',
};

const DEFAULT_ERROR_MESSAGE =
  'Something went wrong, Please try again after sometime';

const AI_INTERVIEW_TYPES = [
  INTERVIEW_TYPES.AI_VIDEO,
  INTERVIEW_TYPES.AI_INTERVIEW_WITHOUT_VIDEO,
];

const SCHEDULE_INTERVIEW_FORMS = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
};

const JOB_STATUS_LIST = {
  APPROVAL_PENDING: {
    color: '#FFD787',
    label: 'Approval Pending',
  },
  OVERDUE: {
    color: '#D7CECD',
    label: 'Overdue',
  },
  DRAFT: {
    color: '#FFBD9F',
    label: 'Draft',
  },
  CLOSED: {
    color: '#AED7FF',
    label: 'Closed',
  },
  PUBLISHED_JOB: {
    color: '#BAE9EF',
    label: 'Published',
  },
};

export const JOB_TAB_TYPES = {
  ALL: 'ALL',
  HOLD: 'HOLD',
  IDLE: 'IDLE',
  DRAFT: 'DRAFT',
  CLOSED: 'CLOSED',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  OVERDUE: 'OVERDUE',
  QUESTIONAIRE: 'QUESTIONAIRE',
  FORCEFULLY_CLOSED: 'FORCEFULLY_CLOSED',
  ONBOARDING_IN_PROGRESS: 'ONBOARDING_IN_PROGRESS',
};

export const JOB_STATUS_TYPES = {
  ALL: 'ALL',
  HOLD: 'HOLD',
  IDLE: 'IDLE',
  DRAFT: 'DRAFT',
  CLOSED: 'CLOSED',
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  OVERDUE: 'OVERDUE',
  REMOVED: 'REMOVED',
  FORCEFULLY_CLOSED: 'FORCEFULLY_CLOSED',
  SCHEDULED_FOR_LATER: 'SCHEDULED_FOR_LATER',
  ONBOARDING_IN_PROGRESS: 'ONBOARDING_IN_PROGRESS',
};

const SCHEDULE_MODES = {
  BASIC: 'Basic',
  ADVANCED: 'Advanced',
};
const SCHEDULE_COMPONENT_MODES = {
  Schedule: 'Schedule',
  ReSchedule: 'ReSchedule',
  CONSULTANCY: 'CONSULTANCY',
  SCHEDULE_REPORTS: 'SCHEDULE_REPORTS',
};

const CONSULTANCY_INTERVIEW_SCHEDULE_TYPES = {
  PRESCREEN: 'PRESCREEN',
  INTERVIEW: 'INTERVIEW',
};

const DEFAULT_QUERY_ARGS = {
  paramsData: {},
};

const RECRUITER_REVIEWER_STATUS = {
  ALL: 'ALL',
  REC: 'REC',
  REV: 'REV',
  NO_ACCESS: 'NO_ACCESS',
};

const PERMISSION_TYPES = {
  // jobs
  ADD_JOB: 'ADD_JOB',
  EDIT_JOB: 'EDIT_JOB',
  DELETE_JOB: 'DELETE_JOB', // pending to connect ----
  APPROVE_JOB: 'APPROVE_JOB', // pending to connect ----

  VIEW_JOB: 'VIEW_JOB',
  VIEW_OPEN_JOB_TAB: 'VIEW_OPEN_JOB_TAB',
  VIEW_APPROVAL_PENDING_JOB_TAB: 'VIEW_APPROVAL_PENDING_JOB_TAB',
  VIEW_OVERDUE_JOB_TAB: 'VIEW_OVERDUE_JOB_TAB',
  VIEW_DRAFT_JOB_TAB: 'VIEW_DRAFT_JOB_TAB',
  VIEW_CLOSED_JOB_TAB: 'VIEW_CLOSED_JOB_TAB',
  VIEW_ALL_STATUS_JOB_TAB: 'VIEW_ALL_STATUS_JOB_TAB',
  VIEW_QUESTIONAIRE_AWAITING_JOB_TAB: 'VIEW_QUESTIONAIRE_AWAITING_JOB_TAB',
  VIEW_HOLD_JOB_TAB: 'VIEW_HOLD_JOB_TAB',
  VIEW_ONBOARDING_PROGRESS_JOB_TAB: 'VIEW_ONBOARDING_PROGRESS_JOB_TAB',

  ADD_CANDIDATE: 'ADD_CANDIDATE',
  EDIT_CANDIDATE: 'EDIT_CANDIDATE',
  VIEW_CANDIDATE: 'VIEW_CANDIDATE',
  VIEW_SCHEDULES_TAB: 'VIEW_SCHEDULES_TAB',
  VIEW_SCORE_CARD_TAB: 'VIEW_SCORE_CARD_TAB',
  VIEW_OFFER_REJECT_TAB: 'VIEW_OFFER_REJECT_TAB',
  VIEW_JOB_ACTIVITY: 'VIEW_JOB_ACTIVITY',
  // candidates
  VIEW_UPCOMING_INTERVIEWS_TAB: 'VIEW_UPCOMING_INTERVIEWS_TAB',
  VIEW_PENDING_INTERVIEWS_TAB: 'VIEW_PENDING_INTERVIEWS_TAB',
  VIEW_REVIEW_PENDING_INTERVIEWS_TAB: 'VIEW_REVIEW_PENDING_INTERVIEWS_TAB',
  VIEW_REVIEW_COMPLETED_INTERVIEWS_TAB:
    'VIEW_REVIEW_COMPLETED_INTERVIEWS_TAB',
  VIEW_CANCELLED_INTERVIEWS_TAB: 'VIEW_CANCELLED_INTERVIEWS_TAB',
  VIEW_ALL_INTERVIEWS_TAB: 'VIEW_ALL_INTERVIEWS_TAB',
  VIEW_MY_AVAILABILITY_TAB: 'VIEW_MY_AVAILABILITY_TAB', //TODO: temporary feature.
  VIEW_INTERVIEW_REVIEW_OF_OTHERS: 'VIEW_INTERVIEW_REVIEW_OF_OTHERS', // pending to connect ----

  // communication
  VIEW_EMAIL_TAB: 'VIEW_EMAIL_TAB',
  VIEW_PUSH_NOTIFICATION_TAB: 'VIEW_PUSH_NOTIFICATION_TAB',
  VIEW_VOICE_BOT_TAB: 'VIEW_VOICE_BOT_TAB',
  VIEW_SMS_TAB: 'VIEW_SMS_TAB',
  VIEW_WHATSAPP_TAB: 'VIEW_WHATSAPP_TAB',
  VIEW_OFFER_LETTER_TAB: 'VIEW_OFFER_LETTER_TAB',

  // registration
  ADD_COMPANY_REGISTRATION: 'ADD_COMPANY_REGISTRATION',
  VIEW_COMPANY_REGISTRATION: 'VIEW_COMPANY_REGISTRATION',
  EDIT_COMPANY_REGISTRATION: 'EDIT_COMPANY_REGISTRATION',
  DELETE_COMPANY_REGISTRATION: 'DELETE_COMPANY_REGISTRATION',

  // settings , settings full permission need to connect
  VIEW_JOB_FIELDS_TAB: 'VIEW_JOB_FIELDS_TAB',
  VIEW_DEPARTMENTS_TAB: 'VIEW_DEPARTMENTS_TAB',
  VIEW_ROLES_TAB: 'VIEW_ROLES_TAB',
  VIEW_TEAM_MEMBERS_TAB: 'VIEW_TEAM_MEMBERS_TAB',
  VIEW_EVALUATION_PARAMETER_TAB: 'VIEW_EVALUATION_PARAMETER_TAB',
  VIEW_SKILLS_TAB: 'VIEW_SKILLS_TAB',
  VIEW_OFFICE_LOCATION_TAB: 'VIEW_OFFICE_LOCATION_TAB',
  VIEW_EDUCATION_TAB: 'VIEW_EDUCATION_TAB',
  VIEW_HIRING_TYPE_TAB: 'VIEW_HIRING_TYPE_TAB',
  VIEW_CERTIFICATE_TAB: 'VIEW_CERTIFICATE_TAB',
  VIEW_EVALUATION_QUESTIONNAIRE_TAB: 'VIEW_EVALUATION_QUESTIONNAIRE_TAB',
  VIEW_PRESCREEN_PARAMETER_TAB: 'VIEW_PRESCREEN_PARAMETER_TAB',
  VIEW_CONSULTANCY_TAB: 'VIEW_CONSULTANCY_TAB',
  VIEW_CONSULTANCY_MEMBERS_TAB: 'VIEW_CONSULTANCY_MEMBERS_TAB',
  VIEW_CLIENTS_TAB: 'VIEW_CLIENTS_TAB',
  VIEW_CLIENTS_MEMBERS_TAB: 'VIEW_CLIENTS_MEMBERS_TAB',
  // questionnaire
  ADD_QUESTIONNAIRE: 'ADD_QUESTIONNAIRE',
  VIEW_QUESTIONNAIRE: 'VIEW_QUESTIONNAIRE',
  DELETE_QUESTIONNAIRE: 'DELETE_QUESTIONNAIRE',
  EDIT_QUESTIONNAIRE: 'EDIT_QUESTIONNAIRE',

  // sidebars
  VIEW_DASHBOARD_MENU: 'VIEW_DASHBOARD_MENU',
  VIEW_JOB_MENU: 'VIEW_JOB_MENU',
  VIEW_CANDIDATE_MENU: 'VIEW_CANDIDATE_MENU',
  VIEW_INTERVIEW_MENU: 'VIEW_INTERVIEW_MENU',
  VIEW_COMMUNICATION_MENU: 'VIEW_COMMUNICATION_MENU',
  VIEW_SETTINGS_MENU: 'VIEW_SETTINGS_MENU',
  VIEW_QUESTION_BANK_MENU: 'VIEW_QUESTION_BANK_MENU',
  VIEW_REGISTRATION_MENU: 'VIEW_REGISTRATION_MENU',
  VIEW_INTERVIEW_PIPELINE_MENU: 'VIEW_REGISTRATION_MENU',
  VIEW_LICENSE_MENU: 'VIEW_LICENSE_MENU',
  VIEW_ENQUIRIES_MENU: 'VIEW_ENQUIRIES_MENU',
  VIEW_REPORT_MENU: 'VIEW_REPORT_MENU',
  VIEW_HIRING_PIPELINE_MENU: 'VIEW_HIRING_PIPELINE_MENU',

  // reports
  VIEW_SCREENING_REPORT: 'VIEW_SCREENING_REPORT',
  VIEW_COMMUNICATION_TAB: 'VIEW_COMMUNICATION_TAB',
  VIEW_SOURCING_REPORT_TAB: 'VIEW_SOURCING_REPORT_TAB',
};

const JOB_ACTIVITY_LOGGER_TYPES = {
  JOB_CREATED: 'JOB_CREATED',
  JOB_APPROVED: 'JOB_APPROVED',
  JOB_PUBLISHED: 'JOB_PUBLISHED',
  JOB_HOLD: 'JOB_HOLD',
  JOB_SENT_FOR_APPROVAL: 'JOB_SENT_FOR_APPROVAL',
  JOB_SCHEDULED_FOR_LATER: 'JOB_SCHEDULED_FOR_LATER',
  JOB_HIRING_TEAM_ADDED: 'JOB_HIRING_TEAM_ADDED',
  JOB_HIRING_TEAM_UPDATED: 'JOB_HIRING_TEAM_UPDATED',
  INTERVIEW_LEVEL_ADDED: 'INTERVIEW_LEVEL_ADDED',
  INTERVIEW_LEVEL_UPDATED: 'INTERVIEW_LEVEL_UPDATED',
  JOB_DETAILS_UPDATED: 'JOB_DETAILS_UPDATED',
  INTERVIEW_SCHEDULED: 'INTERVIEW_SCHEDULED',
  CANDIDATES_SHORTLISTED: 'CANDIDATES_SHORTLISTED',
  CANDIDATES_ADDED: 'CANDIDATES_ADDED',
};

const QUESTION_CRITERION_TYPES = {
  // only 1 answer questions
  OPTIONS: 'Multiple Choice Question',
  // multiple choices and multiple answers are choosable
  MULTI_CHOICE: 'Multi Response Question',
  CHAR: 'Essay',
};

const QUESTIONNAIRE_FIELD_TYPES = {
  OPTIONS: 'OPTIONS',
  MULTI_CHOICE: 'MULTI_CHOICE',
  CHAR: 'CHAR',
  BOOL: 'BOOL',
};

export const JOB_DETAILS_TAB_KEYS = {
  JOB_DETAILS: 'details',
  CANDIDATES: 'candidates',
  SCHEDULES: 'schedules',
  SCORE_CARD: 'score',
  SCHEDULE_REQUESTS: 'requests',
  OFFER_REJECT: 'response',
  ACTIVITY: 'activity',
};

export const OFFERED_REJECTED_TAB_KEYS = {
  OFFERED: 'offered',
  REJECTED: 'rejected',
};

const CANDIDATE_TAB_CONSTANTS = {
  RECENT: 'recent',
  FINALIZED: 'finalized',
  CONFLICTED: 'conflicted',
  SUGGESTED: 'suggested',
  OFFERED: 'offered',
  PIPELINE: 'pipeline',
  REJECTED: 'rejected',
  CONSULTANCY: 'consultancy-candidates',
  NOSHOW: 'no-show',
};

export const CANDIDATE_DETAILS_TAB_KEYS = {
  JOBS: 'jobs',
  RESUME: 'resume',
  PROFILE: 'profile',
  DOCUMENTS: 'documents',
  EXPERIENCE: 'experience',
};

const CURRENT_QUESTIONNAIRE_FORM_STATE_TYPES = {
  NONE: 'NONE',
  VIEW_QUESTION_BANK: 'VIEW_QUESTION_BANK',
  CREATE_QUESTIONNAIRE_FORM: 'CREATE_QUESTIONNAIRE_FORM',
  CREATE_QB_QUESTIONNAIRE_FORM: 'CREATE_QB_QUESTIONNAIRE_FORM',
  CREATE_EXCEL_QUESTIONNAIRE_FORM: 'CREATE_EXCEL_QUESTIONNAIRE_FORM',
  SHOW_CREATED_QUESTIONS: 'SHOW_CREATED_QUESTIONS',
  EDIT_QUESTIONNAIRE_FORM: 'EDIT_QUESTIONNAIRE_FORM',
  REGENERATE_QUESTIONNAIRE_FORM: 'REGENERATE_QUESTIONNAIRE_FORM',
};

export const CURRENCY_CODES = [
  {
    value: 'AED',
    label: 'د.إ (AED)',
  },
  {
    value: 'AFN',
    label: '؋ (AFN)',
  },
  {
    value: 'ALL',
    label: 'L',
  },
  {
    value: 'INR',
    label: '₹ (INR)',
  },
  {
    value: 'USD',
    label: '$ (USD)',
  },
  {
    value: 'EUR',
    label: '€ (EUR)',
  },
  {
    value: 'JPY',
    label: '¥ (JPY)',
  },
  {
    value: 'GBP',
    label: '£ (GBP)',
  },
  {
    value: 'AUD',
    label: 'A$ (AUD)',
  },
  {
    value: 'CAD',
    label: 'C$ (CAD)"),',
  },
  {
    value: 'CHF',
    label: 'CHF (CHF)',
  },
  {
    value: 'CNY',
    label: '¥ (CNY)',
  },
  {
    value: 'HKD',
    label: 'HK$ (HKD)',
  },
  {
    value: 'NZD',
    label: 'NZ$ (NZD)',
  },
  {
    value: 'RUB',
    label: '₽ (RUB)',
  },
  {
    value: 'NZD',
    label: 'NZ$ (NZD)',
  },
  {
    value: 'RUB',
    label: '₽ (RUB)',
  },
  {
    value: 'ZAR',
    label: 'R (ZAR)',
  },
  {
    value: 'BRL',
    label: 'R$ (BRL)',
  },
  {
    value: 'SGD',
    label: 'S$ (SGD)',
  },
  {
    value: 'RUB',
    label: '₽ (RUB)',
  },
  {
    value: 'ZAR',
    label: 'R (ZAR)',
  },
  {
    value: 'BRL',
    label: 'R$ (BRL)',
  },
  {
    value: 'SGD',
    label: 'S$ (SGD)',
  },
  {
    value: 'MXN',
    label: '$ (MXN)',
  },
  {
    value: 'TRY',
    label: '₺ (TRY)',
  },
  {
    value: 'SEK',
    label: 'kr (SEK)',
  },
  {
    value: 'NOK',
    label: 'kr (NOK)',
  },
  {
    value: 'DKK',
    label: 'kr (DKK)',
  },
  {
    value: 'PLN',
    label: 'zł (PLN)',
  },
  {
    value: 'THB',
    label: '฿ (THB)',
  },
  {
    value: 'IDR',
    label: 'Rp (IDR)',
  },
  {
    value: 'MYR',
    label: 'RM (MYR)',
  },
  {
    value: 'PHP',
    label: '₱ (PHP)',
  },
  {
    value: 'ILS',
    label: '₪ (ILS)',
  },
  {
    value: 'SAR',
    label: '﷼ (SAR)',
  },
];

export const PRESCREENING_PAGE_KEY = 'prescreen';

const AVAILABILITY_TYPES = [
  {
    label: 'Full-Time',
    value: 'FULL',
  },
  {
    label: 'Part-Time',
    value: 'PART_TIME',
  },
  // Bring this back when asked for it.
  // {
  //   label: 'None Available',
  //   value: 'NON_AVAILABLE',
  // },
];

const BLOOD_GROUP_TYPES = [
  {
    label: 'A Positive',
    value: 'A_POSITIVE',
  },
  {
    label: 'A Negative',
    value: 'A_NEGATIVE',
  },
  {
    label: 'B Positive',
    value: 'B_POSITIVE',
  },
  {
    label: 'B Negative',
    value: 'B_NEGATIVE',
  },
  {
    label: 'O Positive',
    value: 'O_POSITIVE',
  },
  {
    label: 'O Negative',
    value: 'O_NEGATIVE',
  },
  {
    label: 'AB Positive',
    value: 'AB_POSITIVE',
  },
  {
    label: 'AB Negative',
    value: 'AB_NEGATIVE',
  },
];

const MARITAL_STATUS_TYPES = [
  {
    label: 'Single',
    value: 'SINGLE',
  },
  {
    label: 'Married',
    value: 'MARRIED',
  },
  {
    label: 'Widowed',
    value: 'WIDOWED',
  },
  {
    label: 'Divorced',
    value: 'DIVORCED',
  },
  {
    label: 'Registered Partnership',
    value: 'REGISTERED_PARTNERSHIP',
  },
];

const QUESTION_SOURCE_TYPES = {
  AI: 'AI',
  QUESTION_BANK: 'Question Bank',
  EXCEL: 'EXCEL',
  CUSTOM: 'CUSTOM',
  PRESENTATION_FIX: 'CHAT_GPT', //TODO: Presentation fix. Remove it for functionality later.
};

const BACK_FROM_DATA = {
  INTERVIEW_PAGE: 'INTERVIEW_PAGE',
  SCORE_CARD_PAGE: 'SCORE_CARD_PAGE',
};

const INTERVIEW_STATUS = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  ONHOLD: 'ONHOLD',
  REVIEW_PENDING: 'REVIEW_PENDING',
  REVIEW_COMPLETED: 'REVIEW_COMPLETED',
};

const SLOT_NOON_OPTIONS = [
  {
    label: 'Forenoon',
    value: 'FORENOON',
  },
  {
    label: 'Noon',
    value: 'NOON',
  },
];

export const DOCUMENT_STATUS_TYPES = {
  LOCKED: 'LOCKED',
  CONFIRM: 'CONFIRM',
  NOT_CONFIRM: 'NOT_CONFIRM',
};

export const COMMUNICATION_PAGE_TYPES = {
  EMAIL: 'email',
  PUSH_NOTIFICATIONS: 'push-notifications',
  VOICE_BOT: 'voice-bot',
  SMS: 'sms',
  WHATS_APP: 'whatsapp',
  OFFER_LETTER: 'offer-letter',
};

export const DOCUMENT_TYPES = {
  AADHAAR_CARD: 'AADHAAR_CARD',
  PAN_CARD: 'PAN_CARD',
  PASSPORT: 'PASSPORT',
  VOTER_ID_CARD: 'VOTER_ID_CARD',
};

const SLOT_TIMINGS = {
  forNoonOptions: [
    {
      label: '9.00 - 10.00',
      startDate: dayjs().hour(9),
      endDate: dayjs().hour(10),
      value: '9.00 - 10.00',
    },
    {
      label: '10.00 - 11.00',
      startDate: dayjs().hour(10),
      endDate: dayjs().hour(11),
      value: '10.00 - 11.00',
    },
    {
      label: '11.00 - 12.00',
      startDate: dayjs().hour(11),
      endDate: dayjs().hour(12),
      value: '11.00 - 12.00',
    },
    {
      label: '12.00 - 01.00',
      startDate: dayjs().hour(12),
      endDate: dayjs().hour(13),
      value: '12.00 - 01.00',
    },
  ],
  noonOptions: [
    {
      label: '01.00 - 02.00',
      startDate: dayjs().hour(13),
      endDate: dayjs().hour(14),
      value: '01.00 - 02.00',
    },
    {
      label: '02.00 - 03.00',
      startDate: dayjs().hour(14),
      endDate: dayjs().hour(15),
      value: '02.00 - 03.00',
    },
    {
      label: '03.00 - 04.00',
      startDate: dayjs().hour(15),
      endDate: dayjs().hour(16),
      value: '03.00 - 04.00',
    },
    {
      label: '04.00 - 05.00',
      startDate: dayjs().hour(16),
      endDate: dayjs().hour(17),
      value: '04.00 - 05.00',
    },
  ],
};

const TYPE_OF_EVENT_TYPES = {
  AVAILABLE: {
    label: 'Available',
    value: 'AVAILABLE',
    data: {
      display: 'block',
      allDay: false,
      title: 'Available',
      backgroundColor: '#B8FFD0',
      textColor: '#008A2F',
      classNames: 'eventItem availableEvent',
      className: 'availableEventMajor',
    },
  },
  BOOKED: {
    label: 'Booked',
    value: 'BOOKED',
    data: {
      display: 'block',
      allDay: false,
      title: 'Booked',
      backgroundColor: '#FFE5E5',
      textColor: '#EE6060',
      classNames: 'eventItem bookedEvent',
      className: 'bookedEventMajor',
    },
  },
  LEAVE: {
    label: 'Leave',
    value: 'LEAVE',
    data: {
      // display: 'background',
      display: 'block',
      allDay: true,
      title: 'Leave',
      backgroundColor: '#FDFFB4',
      textColor: '#919432',
      classNames: 'eventItem leaveEvent',
      className: 'leaveEventMajor',
    },
  },
  NOT_AVAILABLE: {
    label: 'Not Available',
    value: 'DEFAULT',
  },
  // FILLED : {
  //   label : 'Filled',
  //   value : 'FILLED',
  // },
};

const FULL_MENU_LIST = {
  dashboard: {
    menuKey: 'Dashboard',
    menuName: 'Dashboard',
    menuIcon: <MdOutlineSpaceDashboard />,
    menuLink: routes.DASHBOARD,
  },
  questionBank: {
    menuKey: 'Question Bank',
    menuName: 'Question Bank',
    menuIcon: <TbFolderQuestion />,
    menuLink: routes.QUESTION_BANK,
  },
  registration: {
    menuKey: 'Registration',
    menuName: 'Registration',
    menuIcon: <PiPencilSimpleLine />,
    menuLink: routes.REGISTRATION,
  },
  settings: {
    menuKey: 'Settings',
    menuName: 'Settings',
    menuIcon: <AiOutlineSetting />,
    menuLink: routes.SETTINGS,
  },
  communication: {
    menuKey: 'Communication',
    menuName: 'Communication',
    menuIcon: <CiHeadphones />,
    menuLink: `${routes.COMMUNICATION}/${COMMUNICATION_PAGE_TYPES.EMAIL}`,
  },
  interviews: {
    menuKey: 'Interviews',
    menuName: 'Interviews',
    menuIcon: <RiBallPenLine />,
    menuLink: routes.INTERVIEWER,
  },
  candidates: {
    menuKey: 'Candidates',
    menuName: 'Candidates',
    menuIcon: <GoPeople />,
    menuLink: `${routes.CANDIDATES}/${CANDIDATE_TAB_CONSTANTS.FINALIZED}`,
  },
  report: {
    menuKey: 'Report',
    menuName: 'Report',
    menuIcon: <LiaClipboardListSolid />,
    subMenu: [
      {
        menuKey: 'Report.Communication Report',
        menuName: 'Communication Report',
        menuIcon: <CiHeadphones />,
        menuLink: routes.COMMUNICATION_REPORT,
      },
      {
        menuKey: 'Report.Candidate Report',
        menuName: 'Candidate Report',
        menuIcon: <VscGraphLine />,
        subMenu: [
          {
            menuName: 'Sourcing Report',
            menuKey: 'Report.Candidate Report.Sourcing Report',
            menuLink: routes.CANDIDATE_SOURCING_REPORT,
          },
          {
            menuName: 'Screening Report',
            menuKey: 'Report.Candidate Report.Screening Report',
            menuLink: routes.CANDIDATE_SCREENING_REPORT,
          },
        ],
      },
    ],
  },
  jobs: {
    menuKey: 'Jobs',
    menuName: 'Jobs',
    menuIcon: <HiOutlineBriefcase />,
    menuLink: `${routes.JOBS}/${JOB_TAB_TYPES.ACTIVE}`,
  },
  license: {
    menuKey: 'License',
    menuName: 'License',
    menuIcon: <TbLicense />,
    menuLink: routes.LICENSE,
  },
  enquires: {
    menuKey: 'Enquires',
    menuName: 'Enquires',
    menuIcon: <PiClipboardText />,
    menuLink: routes.ENQUIRY,
  },
  questions: {
    menuKey: 'Questions',
    menuName: 'Questions',
    menuIcon: <TbFolderQuestion />,
    menuLink: routes.QUESTIONS,
  },
  // -----------------------------
  // interviewPipeLine: {
  //   menuName: 'Interviewer Pipeline',
  //   menuIcon: <CiCalendar />,
  //   menuLink: routes.INTERVIEW_PIPELINE,
  // },
  // hiringPipeLine : {
  //   menuName: "Hiring Pipeline",
  //   menuIcon: <FaBullhorn />,
  //   menuLink: "/hr/hiring-pipeline",
  // },
  // members : {
  //   menuName: "Members",
  //   menuIcon: <MdOutlineRememberMe />,
  //   menuLink: "/hr/members",
  // },
  // taskList :  {
  //   menuName: "Task List",
  //   menuIcon: <BiCalendarCheck />,
  //   menuLink: "/hr/tasklist",
  // },
  // messages : {
  //   menuName: "Messages",
  //   menuIcon: <TiMessages />,
  //   menuLink: "",
  // },
  // notes : {
  //   menuName: "Notes",
  //   menuIcon: <FaRegStickyNote />,
  //   menuLink: "",
  // },
  // companyDetails: {
  //   menuName: "Company Details",
  //   menuIcon:  <RiQuestionAnswerFill />,
  //   menuLink: "/hr/companyInfo",
  // },
};

const SLOT_TYPES = {
  dueDate: {
    value: 'dueDate',
    label: 'Due Date',
  },
  availableSlots: {
    value: 'availableSlots',
    label: 'Available Slots',
  },
  slot: {
    value: 'slot',
    label: 'Create Slot',
  },
};

const SLOT_ACTION_TYPES = {
  HOLD: 'HOLD',
  UNHOLD: 'UNHOLD',
};

const WEB_SOCKET_API_STATUS_TYPES = {
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  EDIT_ERROR: 'EDIT_ERROR',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_ERROR: 'DELETE_ERROR',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_ERROR: 'CREATE_ERROR',
  HOLD_SLOT_SUCCESS: 'HOLD_SLOT_SUCCESS',
  UNHOLD_SLOT_SUCCESS: 'UNHOLD_SLOT_SUCCESS',
  LEAVE_MARK_SUCCESS: 'LEAVE_MARK_SUCCESS',
};

const WEB_SOCKET_BASE_URL = process.env.REACT_APP_WEB_SOCKET_URL;

const DASHBOARD_MONTHLY_TYPES = [
  { value: '1', label: 'Current Month' },
  { value: '2', label: 'Last Two Months' },
  { value: '3', label: 'Last Three Months' },
];

const DASHBOARD_WEEKLY_TYPES = [
  { value: 'currentWeek', label: 'Current Week' },
  { value: 'last15Days', label: '15 Days' },
  { value: 'last1Month', label: '1 Month' },
  { value: 'last3Months', label: '3 Months' },
  { value: 'last6Months', label: '6 Months' },
  { value: 'lastOneYear', label: 'One Year' },
];
const DASHBOARD_SELECTION_TYPES = {
  // only 1 answer questions
  LAST_ONE_MONTH: 'Last One Month',
  // multiple choices and multiple answers are choosable
  LAST_TWO_MONTH: 'Last Two Month',
  LAST_THREE_MONTH: 'Last Three month',
};

const QUESTION_DIFFICULTY_TYPES = {
  EASY: {
    CHAT_GPT_TYPE: 'EASY',
    BACK_END_TYPE: 'EASY',
  },
  MEDIUM: {
    CHAT_GPT_TYPE: 'MEDIUM',
    BACK_END_TYPE: 'MED',
  },
  HARD: {
    CHAT_GPT_TYPE: 'HARD',
    BACK_END_TYPE: 'HARD',
  },
};

// Temporarily added My availability as a tab. Not permanent.
const INTERVIEW_TAB_TYPES = {
  MY_AVAILABILITY: 'MY_AVAILABILITY', // Temporarily added My availability as a tab. Not permanent.
  UPCOMING: 'UPCOMING',
  PENDING: 'PENDING',
  REVIEW_PENDING: 'REVIEW_PENDING',
  REVIEW_COMPLETED: 'REVIEW_COMPLETED',
  CANCELLED: 'CANCELLED',
  ALL_INTERVIEWS: 'ALL_INTERVIEWS',
};

export {
  INTERVIEW_SCHEDULE_STATUS_TYPES,
  SCHEDULE_GRID_STATUS_COLOR_CODE,
  DEFAULT_DATE_FILTER_OPTIONS,
  INTERVIEW_TYPES,
  DEFAULT_PAGINATION_CONFIG,
  QUESTION_SOURCE_TYPES,
  CANDIDATE_RESUME_STATUS,
  CANDIDATE_RESUME_STATUS_COLOR_CODES,
  DEFAULT_ERROR_MESSAGE,
  AI_INTERVIEW_TYPES,
  CANDIDATE_TAB_CONSTANTS,
  SCHEDULE_INTERVIEW_FORMS,
  JOB_STATUS_LIST,
  SCHEDULE_COMPONENT_MODES,
  CONSULTANCY_INTERVIEW_SCHEDULE_TYPES,
  SCHEDULE_MODES,
  DEFAULT_QUERY_ARGS,
  RECRUITER_REVIEWER_STATUS,
  AVAILABILITY_TYPES,
  BLOOD_GROUP_TYPES,
  MARITAL_STATUS_TYPES,
  PERMISSION_TYPES,
  JOB_ACTIVITY_LOGGER_TYPES,
  QUESTION_CRITERION_TYPES,
  CURRENT_QUESTIONNAIRE_FORM_STATE_TYPES,
  BACK_FROM_DATA,
  INTERVIEW_STATUS,
  FULL_MENU_LIST,
  SLOT_NOON_OPTIONS,
  SLOT_TIMINGS,
  TYPE_OF_EVENT_TYPES,
  SLOT_ACTION_TYPES,
  SLOT_TYPES,
  WEB_SOCKET_API_STATUS_TYPES,
  WEB_SOCKET_BASE_URL,
  DASHBOARD_MONTHLY_TYPES,
  DASHBOARD_WEEKLY_TYPES,
  DASHBOARD_SELECTION_TYPES,
  QUESTION_DIFFICULTY_TYPES,
  INTERVIEW_TAB_TYPES,
  QUESTIONNAIRE_FIELD_TYPES,
};
