import React from 'react';
import { useState, useMemo, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider, theme } from 'antd';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useWebSocket from 'react-use-websocket';
import { isEmpty } from 'lodash';
import axios from 'axios';

import './index.css';
import App from './App';
// global import of pdf worker for entire project
import pdfjs from 'Utilities/reactPdf';
import reportWebVitals from './reportWebVitals';
import { useLayoutStore } from 'Store/LayoutStore';
import ErrorBoundary from 'Components/ErrorBoundary/ErrorBoundary';
import { MyProvider } from 'Context/context';
import authService from 'Services/authService';
import { debounce } from 'lodash';

export const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('user_id');
      localStorage.removeItem('userPreference');
      localStorage.removeItem('yvi-persisted');
      window.location.replace('/');
    }
    return Promise.reject(error);
  },
);

axios.interceptors.request.use(
  (config) => {
    // Define your default parameters here
    let nCompanyId = localStorage.getItem('company_id');
    const defaultParams = {
      company_id: nCompanyId,
      // Add more default parameters if needed
    };

    // Merge defaultParams with existing query parameters
    config.params = {
      ...defaultParams,
      ...config.params, // Include any existing params from the request
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const NOTIFICATION_BASE_URL =
  process.env.REACT_APP_NOTIFICATION_WEBSOCKET_URL;

const Index = () => {
  const { yviAntTheme, muiTheme } = useUiManagement();
  const userDetails = JSON.parse(localStorage.getItem('user'));

  const { connection, addNotification, setConnectionState, column_preferece } =
    useLayoutStore((state) => ({
      connection: state.connection,
      addNotification: state.addNotification,
      setConnectionState: state.setConnectionState,
      column_preferece: state?.column_preferece
    }));


  const userId = userDetails?.websocket_id
    ? `${userDetails?.websocket_id}/`
    : '';
  const url = `${NOTIFICATION_BASE_URL}/notifications/${userId}`;
  const socketOptions = {
    shouldReconnect: (event) => true,
    retryOnError: true,
    reconnectAttempts: 10,
    reconnectInterval: 900,
  };
  const { lastJsonMessage, readyState } = useWebSocket(
    url,
    socketOptions,
    connection,
  );

  // useEffect(()=>
  // {
  //   localStorage.removeItem("company_id");
  // },[])

  useEffect(() => {
    if (!isEmpty(lastJsonMessage)) {
      const notification = {
        ...lastJsonMessage,
        read: false,
      };
      addNotification(notification);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    setConnectionState(readyState);
  }, [readyState]);

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            {/* Planned to continue with antd, this is for existing material ui styles  */}
            <ThemeProvider theme={muiTheme}>
              {/* Configuring global antd styles */}
              <ConfigProvider theme={yviAntTheme}>
                <MyProvider>
                  <App />
                </MyProvider>
              </ConfigProvider>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const useUiManagement = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const handleDarkMode = useCallback((flag) => setIsDarkMode(flag), []);
  useEffect(() => {
    if (isDarkMode) {
      document.getElementsByTagName('html')[0].classList.remove('light');
      document.getElementsByTagName('html')[0].classList.add('dark');
    } else {
      document.getElementsByTagName('html')[0].classList.remove('dark');
      document.getElementsByTagName('html')[0].classList.add('light');
    }
  }, [isDarkMode]);
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const yviAntTheme = useMemo(() => {
    return {
      token: {
        fontFamily: '"Poppins", sans-serif',
        colorPrimary: '#0A7F8F',
      },
      components: {},
      algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
    };
  }, [isDarkMode, darkAlgorithm, defaultAlgorithm]);
  const muiTheme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            // light: '#757ce8',
            main: '#0A7F8F',
            // dark: '#002884',
            // contrastText: '#fff',
          },
          mode: isDarkMode ? 'dark' : 'light',
        },
        typography: {
          fontFamily: ['Poppins'],
        },
        // components : {
        //   MuiTab : {
        //     styleOverrides : {
        //       root : {
        //         backgroundColor : '#0A7F8F'
        //       }
        //     }
        //   }
        // }
      }),
    [isDarkMode],
  );
  return {
    handleDarkMode,
    isDarkMode,
    yviAntTheme,
    muiTheme,
  };
};
